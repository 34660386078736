import React , { useState, useCallback } from 'react'
import GoogleButton from 'react-google-button';
import { Link } from 'react-router-dom'
import { useDispatch } from "react-redux";
import authSlice from "../store/slices/auth";
import billingSlice from "../store/slices/billing";
import memberSlice from '../store/slices/member';
import teamSlice from '../store/slices/team';
import axios from "axios";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import Spinner from '../components/Buttons/Spinner';
import Logo from '../assets/img/logo_small.png'
import { Label, Input, Button } from '@windmill/react-ui'






function Login() {
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const redirectUri = 'v1/auth/google';
    const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
    const REACT_APP_GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    let info_url = `${BACKEND_URL}/v1/billing/info`
    let account_url = `${BACKEND_URL}/v1/auth/account`
    let member_url = `${BACKEND_URL}/v1/auth/member`
    const next = localStorage.getItem('next')
    

    const handleLogin = async (email, password) => {
      await axios.post(`${BACKEND_URL}/v1/auth/login/`, 
        { 'username': email, 'password': password }, 
        { skipAuthRefresh: true }
      )
      .then(async (res) => {
        dispatch(
          authSlice.actions.setAuthTokens({
            token: res.data.token,
            refreshToken: res.data.refresh,
            api_token: res.data.api_token,
          })
        );
        dispatch(authSlice.actions.setAccount(res.data.user));

        await axios.get(account_url)
          .then((r) => {
            dispatch(teamSlice.actions.setTeam(r.data))
          })
          .catch((e) => {
            console.log(e)
          })

        await axios.get(member_url)
          .then((r) => {
            dispatch(memberSlice.actions.setMember(r.data))
          })
          .catch((e) => {
            console.log(e)
          })


        // getting billing info
        await axios.post(info_url)
          .then((r) => {
            dispatch(billingSlice.actions.setBilling(r.data));
            setIsLoading(false);
            if (next){
              window.location.href = next
              localStorage.removeItem('next')
            } else{
              navigate("/account/new");
            }
          }).catch((err) => {
            console.log(err);
            setIsLoading(false);
            if (next){
              window.location.href = next
              localStorage.removeItem('next')
            } else{
              navigate("/account/new");
            }
          });
      })
      .catch((err) => {
        try {
          setMessage(err.response.data.detail.toString());
          setIsLoading(false);
        } catch {
          setMessage('Sorry, those credentials don\'t mactch any user');
          setIsLoading(false);
        }
        
      });
    };

    const formik = useFormik({
        initialValues: {
        email: "",
        password: "",
        },
        onSubmit: (values) => {
        setIsLoading(true);
        handleLogin(values.email, values.password);
        },
        validationSchema: Yup.object({
        email: Yup.string().trim().required("Email is required"),
        password: Yup.string().trim().required("Password is required"),
        }),
    });



    const openGoogleLoginPage = useCallback(() => {
  
      const scope = [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile'
      ].join(' ');
  
      const params = {
        response_type: 'code',
        client_id: REACT_APP_GOOGLE_CLIENT_ID,
        redirect_uri: `${BACKEND_URL}/${redirectUri}`,
        prompt: 'select_account',
        access_type: 'offline',
        scope
      };
  
      const urlParams = new URLSearchParams(params).toString();
  
      window.location = `${googleAuthUrl}?${urlParams}`;
    }, [BACKEND_URL, REACT_APP_GOOGLE_CLIENT_ID]);



  return (
    <div className='min-h-screen p-6 bg-gray-100 dark:bg-gray-900'>
      
      <div className='flex justify-center mx-auto my-10'>
        <img
          aria-hidden="true"
          className="w-12 h-12"
          src={Logo}
          alt="Scanerr Logo"
        />
        <h1 className='mt-1 font-bold text-4xl ml-3 text-gray-600 dark:text-gray-400'>Scanerr</h1>
      </div>

      <div className="flex items-center">
        <div className="mt-4  flex-1 h-full max-w-md mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
          <div className="flex flex-col overflow-y-auto">

            <div className="flex items-center justify-center p-6 sm:p-12">
              <div className="w-full">
                <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">Login</h1>

                <form onSubmit={formik.handleSubmit}>

                  <Label>
                    <Input className="rounded-md p-2 mt-1" 
                      type="email" 
                      placeholder="jane@example.com"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.email ? (
                      <div>{formik.errors.email} </div> 
                    ) : null}
                  </Label>

                  <Label className="mt-4">
                    <Input className="rounded-md p-2 mt-1" 
                      type="password" 
                      placeholder="•••••••••••••"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.password ? (
                        <div>{formik.errors.password} </div>
                      ) : null}
                  </Label>

                  <div className="mb-4 text-sm font-semibold text-red-600" hidden={false}>
                    <br></br>
                    {message}
                  </div>

                  <Button className={`mt-4 bg-blue-600 hover:bg-blue-700 w-full flex justify-center`} 
                    type="submit"
                    disabled={isLoading}
                  >
                    <Spinner display={isLoading}/>
                    <p>Log in</p>
                  </Button>
                
                </form>
                <hr className="my-8" />
                <div className='flex'>
                  <div className='m-auto'>
                    <GoogleButton
                      onClick={openGoogleLoginPage}
                      label="Sign in with Google"
                      disabled={!REACT_APP_GOOGLE_CLIENT_ID}
                    />
                  </div>
                </div>
                <p className="mt-4">
                  <Link
                    className="text-sm font-medium text-blue-600 dark:text-blue-400 hover:underline"
                    to="/forgot-password"
                  >
                    Forgot your password?
                  </Link>
                </p>
                <p className="mt-1">
                  <Link
                    className="text-sm font-medium text-blue-600 dark:text-blue-400 hover:underline"
                    to="/register"
                  >
                    Create account
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  )
}

export default Login
