import React, { useState, useEffect, createRef } from 'react'
import axios from 'axios'




function SearchForm () {

    const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
    const searchUrl = `${BACKEND_URL}/v1/ops/search`

    const useFocus = () => {
        const htmlElRef = createRef(null)
        const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
        const removeFocus = () => {htmlElRef.current &&  htmlElRef.current.blur()}
        return [ htmlElRef, setFocus, removeFocus ] 
    }

    const [results, setResults] = useState([])
    const [query, setQuery] = useState('')
    const [open, setOpen] = useState(false)
    const [focus, setFocus] = useState(false)
    const [inputRef, setInputFocus, removeFocus] = useFocus()


    const checkSearch = (e) => {
        if (
            e.key === '/' 
            && ( 
                e.srcElement.localName === 'body' || 
                e.srcElement.id === 'search-form' || 
                e.srcElement.localName === 'main'
            ) 
            && query.length <= 0
        ) {
            if (!focus){
                setInputFocus()
                setFocus(true)
                setOpen(true)
            } else{
                removeFocus()
                setFocus(false)
                setOpen(false)
            }
        }
    }

    const checkClickSearch = (e) => {
        if ( 
            e.srcElement.id !== 'search-form' && 
            e.srcElement.id !== 'search-results'
        ) {
            if (focus || open){
                removeFocus()
                setFocus(false)
                setOpen(false)
            }
        }
    }

    useEffect(() => {  
        window.addEventListener("keydown", checkSearch, true);  
        return () => window.removeEventListener("keydown", checkSearch, true);
    }, [checkSearch]);

    useEffect(() => {  
        window.addEventListener("click", checkClickSearch, true);  
        return () => window.removeEventListener("click", checkClickSearch, true);
    }, [checkClickSearch]);


    const search = async (q) => {
        if (q.length > 0){
            await axios.get(`${searchUrl}?query=${q}`)
                .then((r) => {
                    setResults(r.data)
                })
                .catch((e) => {
                    console.log(e)
                })
        }
        
    }


    const getColors = (type) => {
        console.log(type)
        let colors = '' 
        switch (type){
            case 'site':
                colors = 'dark:bg-blue-600 bg-blue-100 dark:text-white text-blue-700'
                break
            case 'page':
                colors = 'bg-gray-200 dark:bg-gray-600 dark:text-white text-gray-700'
                break
            case 'case':
                colors = 'bg-gray-300 dark:bg-gray-500 dark:text-white text-gray-700'
                break
            case 'issue':
                colors = 'text-orange-700 bg-orange-100 dark:text-white dark:bg-orange-600'
                break
            default:
                colors = 'bg-gray-200 dark:bg-gray-600 dark:text-white text-gray-700'
                break
        }
        return colors
    }




    return(
        <div>
            <div className='w-full flex justify-center relative'>

                <input 
                    className='w-full rounded-md p-2 mr-3 text-sm dark:text-gray-400 text-gray-600 border-0 dark:bg-gray-900 focus:outline-none bg-gray-100'
                    placeholder="press `/` to search"
                    value={query}
                    id='search-form'
                    type='search'
                    onFocus={() => {
                        if (!open){
                            setOpen(true)
                        }
                    }}
                    onChange={(e) => {
                        setQuery(e.target.value === '/' ? '' : e.target.value)
                        if (e.target.value !== '') {
                            setOpen(true)
                            search(e.target.value);
                        } else {
                            setOpen(false)
                        }
                    }}
                    ref={inputRef}
                />

            </div>

            {query.length >= 1 && open &&
                <div id='search-results' className={`md:w-[37.2%] w-[59%] absolute z-50 mx-auto shadow rounded-b-lg p-4 bg-white dark:bg-gray-800 dark:text-gray-300 text-gray-600 border-b-[1px] border-l-[1px] border-r-[1px] border-blue-600 overflow-x-scroll`}>

                    { results && 

                        results.slice().map((resource, i) => (
                            <a key={i} href={resource.path}>
                            <div 
                                className="flex justify-start px-2 py-2 dark:hover:bg-gray-700 hover:bg-gray-100 my-2 rounded-md cursor-pointer" 
                                onClick={() => {
                                    setQuery('')
                                    setOpen(false)
                                }}
                            >   
                                <div className='mr-3 my-auto'>
                                    <div className={`text-center px-2 py-1 rounded-full text-[8px] font-bold ${getColors(resource.type)}`}>
                                        <span>
                                            {resource.type.toUpperCase()}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <h2 className="font-semibold text-sm">
                                        {resource.name}
                                    </h2>
                                </div>

                            </div> 
                            </a>
                        ))

                    }

                </div>
            }

            {query.length < 1 && open &&
                <div id='search-results' className={`md:w-[37.2%] w-[59%] absolute z-50 mx-auto shadow rounded-b-lg p-4 bg-white dark:bg-gray-800 dark:text-gray-400 text-gray-600 border-b-[1px] border-l-[1px] border-r-[1px] border-blue-600 overflow-x-scroll`}>

                    <h2 className="font-semibold text-sm">
                        Start typing to search...
                    </h2>

                    <div className='text-[12px] my-6'>
                        <pre>
                            <code>
                                <p className='mb-[.2rem]'>- case: <span className='ml-[.7rem]'>log in</span></p>
                                <p className='mb-[.2rem]'>- site: <span className='ml-[.7rem]'>example.com</span></p>
                                <p className='mb-[.2rem]'>- page: <span className='ml-[.7rem]'>example.com/page</span></p>
                                <p>- issue: <span className='ml-1'>new issue</span></p>
                            </code>
                        </pre>
                        
                    </div>
                    

                </div>
            }
            

        </div>
    )

}


export default SearchForm