import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom';
import {Table, TableHeader, TableCell, TableBody, TableRow, TableFooter,
  TableContainer, Pagination, Badge, Input} from '@windmill/react-ui'
import axios from 'axios'
import Tooltip from '../Tooltip/Tooltip';
import Loader from '../Buttons/Loader';
import DeleteIssueModal from '../Modals/DeleteIssueModal';
import store from '../../store';
import metricsSlice from '../../store/slices/metrics';



function IssuesTable(props) {

  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const getIssueUrl = `${BACKEND_URL}/v1/ops/issue`;
  const searchIssueUrl = BACKEND_URL + '/v1/ops/issue/search?query='
  const metricsUrl = `${BACKEND_URL}/v1/ops/metrics/home`;

  const [issues, setIssues] = useState(props.issues ? props.issues : null)
  const [query, setQuery] = useState('')

  // get current issues count
  const getMetrics = async () => {
    await axios.get(metricsUrl)
        .then((r) => {
            store.dispatch(metricsSlice.actions.setMetrics(r.data))
        })
        .catch((e) => {
            console.log(e)
        })
}

  const getData = useCallback(() => {
    axios.get(getIssueUrl).then((r) => {
      setIssues(r.data);
      getMetrics();
    });
    // eslint-disable-next-line
  }, [getIssueUrl])
  
  useEffect(() => {
    getData();
    // eslint-disable-next-line 
  }, [getData, props?.proc]);

  const search = async () => {
    if (query !== '') {
        await axios.get(`${searchIssueUrl}${query}`)
            .then((r) => {
              setIssues(r.data)
            })
            .catch((e) => {
                console.log(e)
            })
    } else {
        // console.log(query)
    }
  }

  // setup pages control for table
  const pageTable = 1

  // pagination setup
  const resultsPerPage = 10

  // pagination change control
  function onPageChangeTable(p) {
    if(p){
      var offSet = (p-1)*5
      // var paginatedUrl = `${BACKEND_URL}/v1/ops/issue?limit=10&offset=${offSet}`
      var paginatedUrl = issues.next ? (query.length > 0 ? `${issues.next.split('offset')[0]}offset=${offSet}&query=${query}` : `${issues.next.split('offset')[0]}offset=${offSet}` ) : `${BACKEND_URL}/v1/ops/issue?limit=10&offset=${offSet}`
      axios.get(paginatedUrl)
        .then((r) => {
          setIssues(r.data);
      })
    }
    
  }

  function getDate(issue) {
    if(issue.time_created){
        var date = new Date(issue.time_created).toLocaleDateString();
        var time = new Date(issue.time_created).toLocaleTimeString('en-US');
        var formatedDateTime = `${date} ${time}`
        return formatedDateTime
    }else{
        return '––';
    }
    
  }

  if (issues===null){
   return <Loader item={'Issues'}/>
  }


  return (
    <>
      <TableContainer className="mb-8 dark:bg-gray-800 bg-white">
        <div className='flex justify-between w-full py-4 px-3'>
          <Input
            className='max-w-md h-8 rounded outline-none border-0 bg-gray-100 dark:bg-gray-900'
            value={query}
            placeholder='search by title, resource, or details'
            onChange={(e) => {
              setQuery(e.target.value)
              if (e.target.value !== '') {
                search();
              } else {
                getData();
              }
            }}
          />
        </div>
        <Table>
          <TableHeader>
            <tr className='dark:bg-gray-800 bg-white'>
              <TableCell>Title</TableCell>
              <TableCell>Affected</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Actions</TableCell>
            </tr>
          </TableHeader>
          <TableBody>
            {issues.results.slice((pageTable - 1) * resultsPerPage, pageTable * resultsPerPage).map((issue, i) => (
              <TableRow key={i} className="hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer">
                <TableCell>
                  <Link to={`/issue/${issue.id}`}>
                    <span className='font-semibold text-gray-600 dark:text-gray-400 truncate'>{issue.title}</span>
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/issue/${issue.id}`}>
                    {issue.affected ? issue.affected.str : 'None'}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/issue/${issue.id}`}>
                    {issue.status == 'open' ? <Badge type='primary'>open</Badge> : <Badge type='success'>closed</Badge> }
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/issue/${issue.id}`}>
                    <span className="text-sm">{getDate(issue)}</span>
                  </Link>
                </TableCell>
                <TableCell>
                  <div className='flex justify-start gap-2'>
                    <Tooltip content='delete issue'><DeleteIssueModal type='icon' callBack={getData} issue={issue}/></Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TableFooter>
          <Pagination
            totalResults={issues.count}
            resultsPerPage={resultsPerPage}
            onChange={onPageChangeTable}
            label="Table navigation"
          />
        </TableFooter>
      </TableContainer>
    </>
  )
}




export default IssuesTable

