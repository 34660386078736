import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Gear from '../Buttons/Gear';
import {Table, TableHeader, TableCell, TableBody, TableRow, TableFooter,
  TableContainer, Badge, Pagination} from '@windmill/react-ui'
import axios from 'axios'
import Loader from '../Buttons/Loader';

const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;

function TestcasesTable(props) {

  const emptyTestcases = {results:[], count:0}
  const [testcases, setTestcases] = useState(emptyTestcases)
  const [offSet, setOffSet] = useState(0)
  const [loading, setLoading] = useState(true)
  
  var getTestcasesUrl = `${BACKEND_URL}/v1/ops/testcase?site_id=${props.site.id}&limit=10&offset=${offSet}`;

  
  const getData = async () => {
    await axios.get(getTestcasesUrl)
      .then((r) => {
        setTestcases(r.data)
        setLoading(false)
      })
      .catch((e) => {
        setTestcases(emptyTestcases)
        setLoading(false)
        console.log(e)
      })
  }

  // !!!!! VERY IMPORTANT - this subscribes the component to listen for prop changes from the parent !!!!!!
  useEffect(() => {
    getData()
    return () => {
      getData
    }
    // eslint-disable-next-line 
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      getData();
    }, 20000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [offSet]);


  // setup pages control for table
  const pageTable = 1

  // pagination setup
  const resultsPerPage = 10

  // pagination change control
  function onPageChangeTable(p) {
    if(p){
      var _offSet = (p-1)*5
      setOffSet(_offSet)
      var paginatedUrl = `${BACKEND_URL}/v1/ops/testcase?site_id=${props.site.id}&limit=10&offset=${_offSet}`
      axios.get(paginatedUrl)
        .then((r) => {
          setTestcases(r.data);
        })
        .catch((e) => {
          console.log(e)
        })

    }
    
  }


  const getStatus = (testcase) => {
    var status = 'working'
    var type = 'neutral'
    if (testcase.time_completed){
      status = testcase.passed ? 'Passed' : 'Failed'
      type = testcase.passed ? 'success' : 'danger'
    }
    return <Badge type={type}>{status} {status === 'working' && <Gear size={'ml-2 h-4 w-4 mt-[.1rem]'} color={'dark:text-gray-200 text-gray-600'}/>}</Badge>
  }



  function getDate(testcase) {
    if(testcase.time_created){
      var timestamp = testcase.time_created
      var date = new Date(timestamp).toLocaleDateString();
      var time = new Date(timestamp).toLocaleTimeString('en-US');
      if (date === 'Invalid Date'){
          date = timestamp.substring(0, 10)
          time = timestamp.substring(11, 19) 
        }
      var formatedDateTime = `${date} ${time}`
      return formatedDateTime
    }else{
        return '––';
    }
    
  }

  if (loading){
   return <Loader item={'Testcases'}/>
  }


  return (
    <>
      <TableContainer className="mb-8 dark:bg-gray-800 bg-white">
        <h1 className="text-gray-600 dark:text-gray-300" style={{ margin: '.7em', fontSize: '1.3em', fontWeight: 600, }}>Testcases</h1>
        <Table>
          <TableHeader >
            <tr className='dark:bg-gray-800 bg-white'>
              <TableCell>Case</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Status</TableCell>
            </tr>
          </TableHeader>
          <TableBody>
            {testcases.results.slice((pageTable - 1) * resultsPerPage, pageTable * resultsPerPage).map((testcase, i) => (
              <TableRow key={i} className="hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer">
                
                  <TableCell>
                    <Link to={`/testcase/${testcase.id}`}>
                      <span className='font-semibold text-gray-600 dark:text-gray-400'>{testcase.case_name}</span>
                    </Link>
                  </TableCell>
                
                
                  <TableCell>
                    <Link to={`/testcase/${testcase.id}`}>
                      <span className="text-sm">{getDate(testcase)}</span>
                    </Link>
                  </TableCell>

                
                  <TableCell>
                    <Link to={`/testcase/${testcase.id}`}>
                      {getStatus(testcase)}
                    </Link>
                  </TableCell>
                
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TableFooter>
          <Pagination
            totalResults={testcases.count}
            resultsPerPage={resultsPerPage}
            onChange={onPageChangeTable}
            label="Table navigation"
          />
        </TableFooter>
      </TableContainer>
    </>
  )
}




export default TestcasesTable

