import React, { useState, useEffect } from 'react'
import {Table, TableHeader, TableCell, TableBody, TableRow, TableFooter,
  TableContainer, Badge, Pagination, Button} from '@windmill/react-ui'
import InvoiceSlideModal from '../Modals/InvoiceSlideModal';
import axios from 'axios'
import Loader from '../Buttons/Loader';
import Tooltip from '../Tooltip/Tooltip';
import { DownloadIcon } from '@heroicons/react/outline';






function InvoicesTable(props) {


  const [invoices, setInvoices] = useState(props.invoices ? props.invoices : null)
  const [loading, setLoading] = useState(true)

  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = `${BACKEND_URL}/v1/billing/invoices`;

  // !!!!! VERY IMPORTANT - this subscribes the component to listen for prop changes from the parent !!!!!!
  useEffect(() => {
    function getData(props) {
      axios.get(url)
        .then((r) => {
          if (!r.data.message){
            setInvoices(r.data)
            setLoading(false)
          }else {
            setLoading(false)
          }
        })
    }
    getData()
    // eslint-disable-next-line 
  }, [props])


  // setup pages control for table
  const pageTable = 1

  // pagination setup
  const resultsPerPage = 10

  // pagination change control
  function onPageChangeTable(p) {
    if(p){
      // setOffSet((p-1)*10)
      // let paginatedUrl = `${BACKEND_URL}/v1/ops/scan?lean=true&page_id=${props.page.id}&limit=10&offset=${offSet}`
      // axios.get(paginatedUrl)
      //   .then((r) => {
      //     setScans(r.data);
      // })
      console.log('more invoices')
    }
    
  }

  const getStatusBadge = (status) => {
    let badge = null
    switch(status) {
      case 'draft':
        badge = <Badge type='primary'>{status}</Badge>
        break;
      case 'open':
        badge = <Badge type='neutral'>{status}</Badge>
        break;
      case 'void':
        badge = <Badge type='warning'>{status}</Badge>
        break;
      case 'paid':
        badge = <Badge type='success'>{status}</Badge>
        break;
      case 'uncollectible':
        badge = <Badge type='warning'>{status}</Badge>
        break;
    }
    return badge
  }



  const getPlanBadge = (plan) => {
    let badge = null
    switch(plan) {
      case 'basic':
        badge = <span className='font-bold text-xs py-1 px-2 rounded-xl text-yellow-700 bg-yellow-200 dark:text-yellow-100 dark:bg-yellow-600'>BASIC</span>
        break;
      case 'pro':
        badge = <span className='font-bold text-xs py-1 px-2 rounded-xl text-blue-600 bg-blue-200 dark:text-blue-100 dark:bg-blue-600'>PRO</span>
        break;
      case 'plus':
        badge = <span className='font-bold text-xs py-1 px-2 rounded-xl text-teal-700 bg-teal-200 dark:text-teal-100 dark:bg-teal-600'>PLUS</span>
        break;
      case 'custom':
        badge = <span className='font-bold text-xs py-1 px-2 rounded-xl text-gray-600 bg-gray-200 dark:text-gray-100 dark:bg-gray-600'>CUSTOM</span>
        break;
      case 'enterprise':
        badge = <span className='font-bold text-xs py-1 px-2 rounded-xl text-gray-900 bg-gray-400 dark:text-gray-100 dark:bg-gray-900'>ENTERPRISE</span>
        break;
    }
    return badge
  }


  function getTimeStamp(timestamp) {
    let date = new Date(timestamp * 1000);
    // console.log(date)
    let year = date.getFullYear();
    let month = (date.getMonth() + 1);
    let day = date.getDate();
    
    // Hours part from the timestamp
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let date_str = `${month}/${day}/${year}`
    let localTime = new Date(`${date_str} ${hours}:${minutes}`).toLocaleTimeString('en-US')

    // Will display time in 10:30:23 format
    let formatedDateTime = `${month}/${day}/${year} ${localTime}`
    return formatedDateTime    
  }


  const downloadInvoice = (link) => {
    window.open(link)
  }


  if (loading){
    return <Loader item={'Invoices'}/>
  }


  return (
    <>
      <TableContainer className="mb-8 dark:bg-gray-800 bg-white">
        <h1 className="text-gray-600 dark:text-gray-300" style={{ margin: '.7em', fontSize: '1.3em', fontWeight: 600, }}>Invoices</h1>
        <Table>
          <TableHeader >
            <tr className='dark:bg-gray-800 bg-white'>
              <TableCell>Number</TableCell>
              <TableCell>Plan</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </tr>
          </TableHeader>
          <TableBody>
            {invoices && invoices.data.slice((pageTable - 1) * resultsPerPage, pageTable * resultsPerPage).map((invoice, i) => (
              <TableRow className="" key={i}>
                <TableCell>
                  <span className="text-sm">{invoice.number}</span>
                </TableCell>
                <TableCell>
                  <span className='font-bold text-xs'>{invoice.product_name.toUpperCase()}</span>
                </TableCell>
                <TableCell>
                  <span className="text-sm">{getTimeStamp(invoice.created)}</span>
                </TableCell>
                <TableCell>
                  $ {(invoice.price_amount/100).toFixed(2).toLocaleString()} 
                </TableCell>
                <TableCell>
                  <div>{getStatusBadge(invoice.status)}</div>
                </TableCell>
                <TableCell>
                  <div className='flex items-center space-x-2'>
                    <InvoiceSlideModal type='icon' invoice={invoice}/>
                    <Tooltip content={'download'}>
                      <Button onClick={() => downloadInvoice(invoice.invoice_pdf)} layout="link" size="icon" aria-label="Download Invoice">
                        <DownloadIcon className="w-5 h-5 mx-2 rounded" aria-hidden="true" />
                      </Button>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TableFooter>
          <Pagination
            totalResults={invoices && invoices.data.length}
            resultsPerPage={resultsPerPage}
            onChange={onPageChangeTable}
            label="Table navigation"
          />
        </TableFooter>
      </TableContainer>
    </>
  )
}




export default InvoicesTable
