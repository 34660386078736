import { useState } from 'react';
import { Button } from '@windmill/react-ui';
import AutoCaseModal from '../Modals/AutoCaseModal';
import { 
    ChevronDownIcon, ChevronUpIcon, VideoCameraIcon 
} from '@heroicons/react/outline';
import Dropdown from './Dropdown/Dropdown';
import DropdownItem from './Dropdown/DropdownItem';




function NewCaseButton(props){

    const [isMenuOpen, setIsMenuOpen] = useState(false)

    function openModal() {
        setIsMenuOpen(true);
    }
    
    function closeModal() {
        setIsMenuOpen(false)
    }

    
    const openDocs = () => {
        window.open('https://docs.scanerr.io/guides/app.html#case', '_blank').focus();
    }

    return (
        <div className="text-center relative">
                    
            <Button
                layout="outline"
                size="regular"
                className="text-gray-600 dark:text-gray-400"
                onClick={(e) => {
                    e.nativeEvent.stopImmediatePropagation()
                    if(isMenuOpen){
                        closeModal();
                    }else{
                        openModal();
                    }
                }}
            >
                <div className='flex justify-evenly'>
                    <div className='mr-2'>
                        <p>New Case</p>
                    </div>
                    <div>
                        {isMenuOpen ?
                            <ChevronUpIcon className="w-5 h-5 " aria-hidden="true"/>
                        :
                            <ChevronDownIcon className="w-5 h-5 " aria-hidden="true"/>
                        }
                    </div>
                </div>
                
            </Button>
            <Dropdown
                classes={'-ml-24'}
                isOpen={isMenuOpen}
                onClose={() => {
                    closeModal()
                }}
            >
                <DropdownItem onClick={openDocs} className="z-40 relative">
                    <VideoCameraIcon className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
                    <span>Record</span>
                </DropdownItem>
                <AutoCaseModal type='dropdown' callBack={props.callBack}/>
            </Dropdown>

        </div>
    )
}


export default NewCaseButton