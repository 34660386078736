import React , { useEffect } from 'react'
import authSlice from "../store/slices/auth";
import store from '../store';



function GoogleConfirm() {

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const next = localStorage.getItem('next')

  const user = {
    id: urlParams.get('id'),
    username: urlParams.get('username'),
    email: urlParams.get('email'),
    is_active: urlParams.get('is_active'),
    created: urlParams.get('created'),
    update: urlParams.get('updated'),
  }


  useEffect(() => {
  
    const saveInfo = () => new Promise((resolve, reject) => {
        store.dispatch(
          authSlice.actions.setAuthTokens({
              api_token: urlParams.get('api_token'),
              token: urlParams.get('access'),
              refreshToken: urlParams.get('refresh'),
            })
        );
        store.dispatch(
            authSlice.actions.setAccount(user)
        );
        resolve();
      })

    saveInfo().then(() => {
      if (next){
        window.location.href = next
        localStorage.removeItem('next')
      } else{
        window.location.href = '/account/new';
      }
    })

    },[urlParams, user])


    return (
        <div>
        </div>
    )
    
}
    
export default GoogleConfirm;