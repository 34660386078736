import React, { useState } from 'react';
import { DocumentDuplicateIcon } from '@heroicons/react/solid';
import axios from "axios";
import toast from 'react-hot-toast';
import { Button } from '@windmill/react-ui';
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'
import DropdownItem from '../Buttons/Dropdown/DropdownItem'
import Spinner from '../Buttons/Spinner'




function CopyCaseModal(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/ops/case/copy'

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  const handleCopyCase = (evt) => {
    evt.preventDefault();
    setIsLoading(true);

    axios.post(url, {'case_id': props.case.id})
    .then((r) => {
      if (r.data) {
        console.log(r.data);
        setIsLoading(false);
        setIsModalOpen(false);
        toast.success('Case Coppied!');
        window.location.href = `/case/${r.data.id}`;
      }
    }).catch((e) => {
      if (e.response) {
        toast.error(e.response.data.reason)
        setIsLoading(false);
      }
    });
      
  }

  const ButtonType = () => {
    if(props.type==='icon'){
      return(
        <div>
          <Button onClick={openModal} layout="link" size="icon" aria-label="Copy Case">
            <DocumentDuplicateIcon className="w-5 h-5" aria-hidden="true" />
          </Button>
        </div>
      )
    }else if (props.type==='icon-btn'){
      return(
        <div>
          <Button onClick={openModal} layout="outline" size="small" aria-label="Copy Case" className='px-1'>
            <DocumentDuplicateIcon className="w-5 h-5" aria-hidden="true" />
          </Button>
        </div>
      )
    }else if(props.type==='button'){
      return (
        <Button layout="outline" iconRight={DocumentDuplicateIcon} onClick={openModal}>
            <span>Copy Case</span>
        </Button>
      )
    } else if(props.type==='dropdown'){
      return (
        <DropdownItem tag="a" onClick={openModal} className="z-40 relative">
          <DocumentDuplicateIcon className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
          <span>Copy Case</span>
        </DropdownItem>
      )
    }
  }



  return (
    <>

      {ButtonType()}

      <Modal isOpen={isModalOpen} onClose={closeModal} title={'Copy Case'}>
        <ModalBody>
          <p>This will create a new copy of "{props.case.name}" with all the same data and associations.</p>
        </ModalBody>
        <ModalFooter>
          <div className="hidden sm:block">
            <Button layout="outline" onClick={handleCopyCase} disabled={isLoading}>
              <Spinner display={isLoading}/>
              Copy Case
            </Button>
          </div>
          <div className="block w-full sm:hidden">
            <Button block size="large" layout="outline" onClick={handleCopyCase} disabled={isLoading}>
            <Spinner display={isLoading}/>
              Copy Case
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default CopyCaseModal
