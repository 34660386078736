import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2'
import { Badge } from '@windmill/react-ui';
import Gear from '../Buttons/Gear';
import TestTypesCard from './TestTypesCard'
import axios from 'axios';



function TestScoreCard(props) {

    const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
    const scanUrl = `${BACKEND_URL}/v1/ops/scan`;

    const [testScore, setTestScore] = useState('')
    const [bad, setBad] = useState('')
    const [scores, setScores] = useState([])
    const [labels, setLabels] = useState([])
    const [colors, setColors] = useState([])
    const [cutout, setCutout] = useState(90)
    const [firstScan, setFirstScan] = useState(null)
    const [secondScan, setSecondScan] = useState(null)
    const [scan1Health, setScan1Health] = useState({'value': 'working', 'badge': 'neutral'})
    const [scan2Health, setScan2Health] = useState({'value': 'working', 'badge': 'neutral'})



    const getScans = async () => {
        // get first
        await axios.get(`${scanUrl}/${props.test.pre_scan}/lean`)
            .then((r) => {
                setFirstScan(r.data)
                var health = getHealth(r.data);
                console.log(health)
                setScan1Health(health)
            })
            .catch((e) => {
                console.log(e)
            })
        // get second
        await axios.get(`${scanUrl}/${props.test.post_scan}/lean`)
            .then((r) => {
                setSecondScan(r.data)
                var health = getHealth(r.data);
                console.log(health)
                setScan2Health(health)
            })
            .catch((e) => {
                console.log(e)
            })
    }


    const getHealth = (scan) => {
        var badge = 'neutral';
        var health = 'no data';
        var score_int = 0;
        var value = health;
        var d = 0

        if (scan.lighthouse !== null){
            if (scan.lighthouse.scores.average !== null){
                score_int += parseInt(scan.lighthouse.scores.average)
                d += 1
            }
        }
        if (scan.yellowlab !== null){
            if (scan.yellowlab.scores.globalScore !== null){
                score_int += parseInt(scan.yellowlab.scores.globalScore)
                d += 1
            }
        }
        if (score_int !== 0 ){
            score_int = Math.round((score_int / d) * 10) / 10;
            if (score_int >= 75) {
                health = 'Good';
                badge = 'success';
            } else if (score_int >= 60 && score_int < 75) {
                health = 'Okay';
                badge = 'warning';
            } else {
                health = 'Poor';
                badge = 'danger';
            }
            value = health+' - '+score_int+'%'
        }

        if (scan.time_completed === null){
            value = 'working'
            badge = 'neutral'
        }
        
        return {'value': value, 'badge': badge}
    }


    const resizeScore = () => {
        // setting cutout
        var width = window.innerWidth
        if (width >= 766){
            setCutout(92)
        } if (width < 766){
            setCutout(70)
        }
    }


    const scrs = {
        'html': {
            'weight': 4,
            'name': 'html',
            'color': '#2DD4BF',
            'raw_score': props.test.component_scores.html && props.test.component_scores.html.toFixed(2)
        },
        'logs': {
            'weight': 2.5,
            'name': 'logs',
            'color': '#FFF383',
            'raw_score': props.test.component_scores.logs && props.test.component_scores.logs.toFixed(2)
        },
        'lighthouse': {
            'weight': 1,
            'name': 'lighthouse',
            'color': '#68D0ED',
            'raw_score': props.test.component_scores.lighthouse && props.test.component_scores.lighthouse.toFixed(2)
        },
        'yellowlab': {
            'weight': 1,
            'name': 'yellowlab',
            'color': '#0694A2',
            'raw_score': props.test.component_scores.yellowlab && props.test.component_scores.yellowlab.toFixed(2)
        },
        'vrt': {
            'weight': 4,
            'name': 'vrt',
            'color': '#1C64F2',
            'raw_score': props.test.component_scores.vrt && props.test.component_scores.vrt.toFixed(2)
        },
    }

    
    const getScores = () => {
        var _scores = []
        var _labels = []
        var _colors = []
        var totalW = 0
        var types = props.test.type
        
        // get total weight
        for (var i in types){totalW += scrs[types[i]].weight};
        
        // fill data set
        for (var i in types){
            // calc weighted score 
            var scr = Math.round(
                (
                    ((scrs[types[i]].raw_score * scrs[types[i]].weight)) / (totalW * props.test.score)
                ) * 100
            ) / 100 
            _scores.push(scr) 
            _labels.push(scrs[types[i]].name)
            _colors.push(scrs[types[i]].color)
        };

        var testScore = props.test.score ? (props.test.score === 100 ? props.test.score : props.test.score.toFixed(2)) : 0
        var badData = (100 - testScore) / 100

        setBad(badData)
        setTestScore(testScore)

        setScores(_scores);
        setColors(_colors);
        setLabels(_labels);
    }


    useEffect(() => {
        getScans();
        resizeScore();
        getScores();
    }, [props?.test])
    

    const getDate = (scan) => {
        if (scan) {
            if(scan.time_created){
                var date = new Date(scan.time_created).toLocaleDateString();
                var time = new Date(scan.time_created).toLocaleTimeString('en-US');
                var formatedDateTime = `${date} ${time}`
                return formatedDateTime
            }
        }
        else{
            return '––';
        }
    }

    const testData = {
        data: {
          datasets: [
            {
              data: [...scores, bad],
              backgroundColor: [...colors, '#0694a203'],
              borderColor: '#9ca3af',
            },
          ],
          labels: [...labels, 'failed'],
        },
        options: {
            responsive: true,
            cutout: cutout,
            plugins: {
              tooltip: {
                enabled: true,
                display: false,
                position: 'nearest',
                callbacks: {
                    label: function(context) {
                        let type = context.label || null;
                        let label = context.label
                        try{
                            let w = scrs[type].weight
                            let s = scrs[type].raw_score
                            label = `score: ${s}% | weight: ${w}`
                            return label;
                        } catch{
                            return label;
                        }
                    }
                }
              },
              legend: {
                display: false,
              },
            },
          },
        
      }


    return (
        <>
            <div className="grid gap-6 md:grid-cols-5 grid-cols-1 mb-6">
                <div className="md:col-start-1 md:col-end-3 text-center text-gray-600 dark:text-gray-100 mx-auto">
                    <div className="text-center p-8 md:p-0 md:h-[16rem] md:w-[16rem] h-[18rem] w-[18rem]">
                        <div className=''><Doughnut className='z-50' {...testData} /></div>
                        <div className="relative font-semibold text-center mx-auto md:text-3xl text-2xl lg:text-4xl z-0" style={{ marginTop: '-58%', marginBottom: '20%',}}>{testScore}%</div>
                    </div>
                </div>
                <div className="md:col-start-3 md:col-end-6 w-full mb-6">
                    
                    <div className='mx-auto'>

                        <div className='max-w-[32rem]'>
                            <Link to={`/scan/${props.test.pre_scan}`} >
                                <div  className="py-2 px-4 flex justify-between cursor-pointer w-full text-center rounded-lg shadow-md text-blue-600 dark:text-blue-200 border-blue-600 border-2 hover:-translate-y-1 ease-in-out duration-200">
                                    <div>
                                        <p className="md:text-xl text-lg font-semibold">First Scan</p>
                                    </div>
                                    <div className='my-auto flex justify-end'>
                                        <div><Badge type={scan1Health.badge}>{scan1Health.value} {scan1Health.value === 'no data' && <Gear size={'ml-2 h-4 w-4 mt-[.1rem]'} color={'dark:text-gray-200 text-gray-600'}/>}</Badge></div>
                                        <div className='md:ml-12 ml-6'><span className="md:text-xs text-[.6rem] text-gray-500 dark:text-gray-300">{getDate(firstScan)}</span></div> 
                                    </div>
                                </div>
                            </Link>
                        </div>
                        
                        <div className='max-w-[32rem]'>
                            <Link to={`/scan/${props.test.post_scan}`} >
                            <div className="py-2 px-4 flex justify-between cursor-pointer mt-4 w-full text-center rounded-lg shadow-md text-teal-600 dark:text-teal-200 border-teal-600 border-2 hover:-translate-y-1 ease-in-out duration-200">
                                <div>
                                    <p className="md:text-xl text-lg font-semibold ">Second Scan</p>
                                </div>
                                <div className='my-auto flex justify-end'>
                                    <div><Badge type={scan2Health.badge}>{scan2Health.value} {scan2Health.value === 'no data' && <Gear size={'ml-2 h-4 w-4 mt-[.1rem]'} color={'dark:text-gray-200 text-gray-600'}/>}</Badge></div>
                                    <div className='md:ml-12 ml-6'><span className="md:text-xs text-[.6rem] text-gray-500 dark:text-gray-300">{getDate(secondScan)}</span></div>
                                </div>
                            </div>
                            </Link>
                        </div>

                        <div className='py-2 px-4 border-2 border-gray-500 dark:border-gray-400 max-w-[32rem] my-6 rounded-lg'>
                            <div className='flex justify-between'>
                                <div className='flex justify-start'>
                                    <h3 className='mr-4 text-xl font-semibold text-gray-600 dark:text-gray-300'>Status:</h3> 
                                    <div>
                                        { props.test.status === 'working' ?
                                            <span className='text-gray-700 dark:text-white bg-gray-200 dark:bg-gray-700 inline-flex px-2 text-xs font-medium leading-5 rounded-full'>working <Gear size={'ml-2 h-4 w-4 mt-[.1rem]'} color={'dark:text-gray-200 text-gray-600'}/></span>
                                        :
                                            <Badge type={props.test.status === 'failed' ? 'danger' : 'success'}>{props.test.status && props.test.status.charAt(0).toUpperCase()+props.test.status.slice(1)}</Badge>
                                        }
                                    
                                    </div>
                                </div>

                                <div className='ml-10 flex justify-start'>
                                    <h3 className='mr-4 text-xl font-semibold text-gray-600 dark:text-gray-300'>Threshold:</h3> 
                                    <h3 className='text-lg font-bold text-gray-600 dark:text-gray-100'>{props.test.threshold}%</h3>
                                </div>
                            </div>

                            <TestTypesCard types={props.test.type}/>
                        </div>

                    </div>
                
                    
                </div>
            </div>
        
           
            
        
        </>
    )
}

export default TestScoreCard