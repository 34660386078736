import React, { useState, useEffect } from 'react';
import { Button } from '@windmill/react-ui';
import { SearchIcon } from '../../icons';
import Markdown from 'react-markdown'
import Tooltip from '../Tooltip/Tooltip';
import { XIcon } from '@heroicons/react/outline';

function AuditsSlideModalLH(props) {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visibleAudits, setVisibleAudits] = useState([]);


  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  const toggleAudit = (id) => {
    if (visibleAudits.includes(id)){
      var ids = [...visibleAudits]
      var newIds = ids.filter(i => i !== id)
      setVisibleAudits([...newIds])
    }else{
      setVisibleAudits([...visibleAudits, id])
    }
  }


  const updateLinks = () => {
    try {
      var div = document.getElementById(`lh-${props.cat}-audits`)
      var links = div.getElementsByTagName('a')
      for (var i in links) {
        if (links[i]){
          links[i].setAttribute("class", "hover:underline text-blue-600 dark:text-blue-400")
          links[i].setAttribute("target", "_blank");
        }
      }
    } catch {}
    
  }

  useEffect(() => {
    setTimeout(() => {
      updateLinks();
    }, 300);
    
  }, [updateLinks])
  

  const getColor = (score, is_binary) => {

    if (is_binary){
      score = score * 100
    }
    var color = '#38B43F'
    if (score >= 80) {
      color = '#38B43F'
    } else if (80 >= score && score >= 70) {
      color = '#82B436'
    } else if (70 >= score && score >= 50) {
      color = '#ACB43C'
    } else if (50 >= score && score >= 30) {
      color = '#B49836'
    } else if (30 >= score && score >= 0) {
      color = '#B46B34'
    } else {
      color = '#B43A29'
    }
    return color
  }


  const getListLH = (obj) => {
    var list = []
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value !== 'object' && value !== null ){
        list.push(value)
      }
      if (typeof value === 'object'){
        for (const [k, v] of Object.entries(value)){
          if (k === 'snippet' || k === 'url'){
            var node = (
              <div className='flex justify-start text-xs overflow-auto'>
                <div className='overflow-auto'><code>{v}</code></div>
              </div>
            )
            list.push(node)
          }
        }
      }
      
    }
    return list
  }




  const formatLightHouseDetails = (details) => {
    if (details.type === 'table'){
      return(
        <div className='overflow-auto'>
          <table className='table-auto border border-slate-500'>
            <tbody>
              <tr className='border border-slate-500 p-1'>
                {details.items[0] && Object.keys(details.items[0]).map((key, i) => (
                 <th className='border border-slate-500 p-1 text-sm' key={i}>{key}</th>
                ))}
              </tr>
              {details.items.map((item, i) => (
                <tr key={i} className='border border-slate-500 p-1 text-xs'>
                  {getListLH(item).map((v, i) => (
                    <td className='border border-slate-500 p-1' key={i}>{v}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    }
  }



  const ButtonType = () => {
    if(props.type==='icon'){
      return(
          <Tooltip content='view details'>
          <Button onClick={openModal} layout="link" size="icon" aria-label="Scan">
            <SearchIcon className="w-5 h-5 mx-2" aria-hidden="true" />
          </Button>
          </Tooltip>
      )
    }else if(props.type==='button'){
      return (
        <div>
          <Button onClick={openModal} iconRight={SearchIcon} layout='outline'>
            View Audits
          </Button>
        </div>
      )
    }else if(props.type==='text'){
      return (
        <span onClick={openModal} className="cursor-pointer">
          { props.text ? props.text : 'View Audits' }
        </span>
      )
    }else if(props.type==='wrapper'){
      return (
        <div onClick={openModal} className="cursor-pointer">
          {props.children}
        </div>
      )
    }
  }



  return (
    <>

      {ButtonType()}
        
          <div className={`shadow-lg p-6 rounded-lg bg-white dark:bg-gray-700 max-w-2xl min-w-[50%] h-[98%] z-50 absolute top-2 -right-3 ${isModalOpen ? '-translate-x-6 transition ease-in-out duration-300' : 'hidden translate-x-6 transition ease-in-out duration-300'}`}>  

            {/* modal header */}
            <div className='flex justify-between w-full mb-2'>
              <div>
                <h1 className='text-lg font-bold text-gray-700 dark:text-gray-400'>{props.cat} Audits</h1>
              </div>
              <div>
                <div onClick={closeModal} className='cursor-pointer text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white'>
                  <XIcon className="w-5 h-5" aria-hidden="true" />
                </div>
              </div>
            </div>

            {/* modal body */}
            <div className='overflow-auto h-[95%]' id={`lh-${props.cat}-audits`}>
                {props.audits && 
                props.audits.map((audit, i) => (

                  <div className='flex justify-start' key={i}> 
                    <div className='my-4 h-auto w-2 rounded-l-md' style={{backgroundColor: (audit.policy ? getColor(audit.score, false) : getColor(audit.score, true) ) }}></div>
                    <div className='my-4 rounded-r-md px-2 py-2 bg-gray-100 dark:bg-gray-600 w-full text-gray-600 dark:text-gray-300' >
                      <div 
                        className='flex justify-between cursor-pointer'
                        onClick={() => {toggleAudit((props.cat+i).replace(/\s+/g, '-'))}}
                        >
                        <h3 className='font-semibold text-[14px] mb-2 text-left'>
                          <Markdown>{audit.title}</Markdown>
                        </h3>
                        <h3>
                          { audit.displayValue && <span className='text-[14px] font-bold my-2'>{audit.displayValue}</span> }
                        </h3>
                      </div>
                      { visibleAudits.includes((props.cat+i).replace(/\s+/g, '-')) &&
                        <div id={audit.id ? `${props.cat}-${audit.id}` : (props.cat+i).replace(/\s+/g, '-') } className={'whitespace-normal p-4 mt-2 cursor-text overflow-auto text-left'}>
                          { audit.displayValue && <h5 className='text-sm font-bold my-2 underline'>{audit.displayValue}</h5> }
                          { audit.description  && <h5 className='text-sm mb-4'><Markdown>{audit.description}</Markdown></h5> }
                          { audit.details && formatLightHouseDetails(audit.details) }
                        </div>
                      }
                    </div>
                  </div>
                  
                ))}

            </div>
            
          

          </div>
    </>
  )
}

export default AuditsSlideModalLH


