import React, {useState, useEffect} from 'react'
import { Input, Button } from '@windmill/react-ui'
import axios from "axios";
import Spinner from '../components/Buttons/Spinner'
import { TrashIcon, PlusIcon } from '../icons/index'
import store from '../store';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Toggle from '../components/Toggle/Toggle';
import memberSlice from '../store/slices/member'
import teamSlice from '../store/slices/team'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/semantic-ui.css'
import BillingOnboarding from '../components/Forms/BillingOnboarding';
import toast from 'react-hot-toast';
import billingSlice from '../store/slices/billing';








function NewTeam() {


  const [teamName, setTeamName] = useState('');
  const [team, setTeam] = useState('');
  const [phone, setPhone] = useState('');
  const [members, setMembers] = useState([{email: ''}, ]);
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [view, setView] = useState('phone');
  const [key, setKey] = useState(null);
  const [interval, setInterval] = useState('month');
  const [plan, setPlan] = useState('free');
  const [priceAmount, setPriceAmount] = useState(null);
  const [maxSites, setMaxSites] = useState(1);
  const [maxPages, setMaxPages] = useState(3);
  const [maxSchedules, setMaxSchedules] = useState(0);
  const [retentionDays, setRetentionDays] = useState(15);
  const [testcases, setTestcases] = useState(0);
  const [scans, setScans] = useState(30);
  const [tests, setTests] = useState(30);
  const [coupon, setCoupon] = useState('');
  const [discount, setDiscount] = useState(1);
  const [viewCoupon, setViewCoupon] = useState(false);

  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const account_url = `${BACKEND_URL}/v1/auth/account`
  const member_url = `${BACKEND_URL}/v1/auth/member`
  const strip_key_url = `${BACKEND_URL}/v1/billing/stripe/key`
  const billing_info_url = `${BACKEND_URL}/v1/billing/info`
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const qTeam = urlParams.get('team') // will be "null" if not present
  const qCode = urlParams.get('code') // will be "null" if not present
  const qMember = urlParams.get('member') // will be "null" if not present
  const token = store.getState().auth.token
  const user = store.getState().auth.account
  const headers = {'Authorization': `Bearer ${token}`}
  const configs = {headers: headers, skipAuthRefresh: true}

  // billing defaults
  const setupData = {
    'name': plan, 
    'interval': interval,
    'price_amount': priceAmount, 
    'max_sites': maxSites, 
    'max_pages': maxPages,
    'max_schedules': maxSchedules,
    'retention_days': retentionDays,
    'scans_allowed': scans,
    'tests_allowed': tests,
    'testcases_allowed': testcases,
    'meta': {
      'coupon': {
        'code': coupon,
        'discount': discount
      }
    }
    
  }

  useEffect(() => {
    // check if user has invite link
    preLoad()
  }, [])

  // load stripe key
  useEffect(() => {
    getKey()
  },[BACKEND_URL])


  const getKey = async () => {
    setKey(null)
    await axios.post(strip_key_url, null, configs)
    .then((r) => {
      setKey(r.data.key);
    }).catch((err) => {
      console.log(err);
    });
  }


  const preLoad = async () => {

    if (!token){
      console.log(window.location.href)
      localStorage.setItem("next", window.location.href);
      window.location.href = '/register'
    }

    if (qTeam !== undefined && qTeam !== null){
      var data = {
        'id': qMember,
        'code': qCode,
        'status': 'active'
      }
      // sending invite data 
      await axios.post(member_url, data, configs)
        .then((r) => {
          store.dispatch(memberSlice.actions.setMember(r.data))
          console.log(r.data)
          axios.get(`${account_url}`, configs)
            .then((r) => {
              updateBilling();
              store.dispatch(teamSlice.actions.setTeam(r.data))
              console.log(r.data)
              window.location.href = '/onboard';
            })
            .catch((e) => {
              console.log(e)
            })
        })
        .catch((e) => {
          console.log(e)
        })
    }
    else {
      // checking if already a Member
      await axios.get(member_url, configs)
        .then((r) => {
          updateBilling();
          store.dispatch(memberSlice.actions.setMember(r.data))
          axios.get(`${account_url}`, configs)
            .then((r) => {
              store.dispatch(teamSlice.actions.setTeam(r.data))
              // check if account type is new
              if (r.data.type==='new'){
                console.log(`account type is => ${r.data.type}`)
                setReady(true)
                setView('billing')
              }else {
                console.log(r.data)
                window.location.href = '/onboard';
              }
            })
            .catch((e) => {
              console.log(e)
            })
        })
        .catch((e) => {
          console.log(e)
          console.log('user not yet a member')
          setReady(true)
        })
      
    }
  }



  const handleKeyDownTeam = (evt) => {
    if (evt.key === 'Enter') {
      createTeam()
    }
  }


  const handleKeyDownMemebr = (evt) => {
    if (evt.key === 'Enter') {
      sendInvites()
    }
  }


  const createTeam = async () => {
    var data = {
      'name': teamName,
      'phone': phone,
      'type': 'new',
      'max_sites': 1,
      'max_pages': 3,
      'max_schedules': 0,
      'retention_days': 3,
    }
    if (teamName.length <= 0 || loading ){return null}
    setLoading(true)
    await axios.post(account_url, data, configs)
      .then((r) => {
        setTeam(r.data)
        store.dispatch(teamSlice.actions.setTeam(r.data))
        var data = {
          'account': r.data.id,
          'email': user.username,
          'status': 'active',
          'type': 'admin',
          'code': r.data.code,
          'phone': phone,
        }
        console.log(data)
        axios.post(member_url, data, configs)
          .then((r) => {
            var data = {
              'id': r.data.id
            }
            // updating member with user
            axios.post(member_url, data, configs)
              .then((r) => {
                store.dispatch(memberSlice.actions.setMember(r.data))
                setView('member')
                setLoading(false)
              })
              .catch((e) => {
                console.log(e)
                setLoading(false)
              })
          })
          .catch((e) => {
            console.log(e)
            setLoading(false)
          })
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }




  const sendInvites = async () => {
    if (members[0]['email'].length <= 0 || !members[0]['email'].includes('@') || loading) {return null}
    setLoading(true)
    for (var i in members){
      var data = {
        'account': team.id,
        'email': members[i]['email'],
        'status': 'pending',
        'type': 'contributor'
      }
      await axios.post(member_url, data, configs)
        .then((r) => {
          console.log(r.data)
        })
        .catch((e) => {
          console.log(e)
          setLoading(false)
        })
    }
    window.location.href = '/onboard';
  }


  const handleMemberChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...members];
    list[index][name] = value;
    setMembers(list);
  };

  const handleRemoveMember = index => {
    const list = [...members];
    list.splice(index, 1);
    setMembers(list);
  };
  
  const handleAddMember = () => {
    setMembers([...members, {email: ''}]);
  };



  const setBillingInfo = (p) => {
    switch (p) {
      case 'free':
        setPlan('free')
        setPriceAmount(0)
        setMaxPages(3)
        setMaxSites(1)
        setMaxSchedules(0)
        setTestcases(0)
        setScans(30)
        setTests(30)
        setRetentionDays(15)
        break
      case 'basic':
        setPlan('basic')
        setPriceAmount(interval === 'month' ? (8900*discount) : ((8000*12)*discount))
        setMaxPages(3)
        setMaxSites(5)
        setMaxSchedules(5)
        setTestcases(500)
        setScans(1000)
        setTests(1000)
        setRetentionDays(30)
        break
      case 'pro':
        setPlan('pro')
        setPriceAmount(interval === 'month' ? (17900*discount) : ((16000*12)*discount))
        setMaxPages(5)
        setMaxSites(10)
        setMaxSchedules(10)
        setTestcases(1000)
        setScans(2000)
        setTests(2000)
        setRetentionDays(90)
        break
      case 'plus':
        setPlan('plus')
        setPriceAmount(interval === 'month' ? (34900*discount) : ((31400*12)*discount))
        setMaxPages(10)
        setMaxSites(25)
        setMaxSchedules(25)
        setTestcases(2500)
        setScans(5000)
        setTests(5000)
        setRetentionDays(180)
        break
    }
  }


  const checkCoupon = (c) => {
    switch (c) {
      case 'WEBINAR-524':
        setDiscount(.5)
        toast.success('50% off code applied!')
        break;
      default:
        setDiscount(1)
    }
  }

  const billingCallBack = async () => {
    await updateBilling()
      .then(() => {
        toast.success('billing added!')
      })
      .then(() => {
        window.location.href = '/onboard'
      })
      .catch((e) => {
        console.log(e)
      })
   
  }


  const updateBilling = async () => {
    await axios.post(billing_info_url, null, configs)
      .then((r) => {
        store.dispatch(billingSlice.actions.setBilling(r.data))
      })
      .catch((e) => {
        console.log(e)
      })
  }


  const setFreeAccount = async () => {
    setLoading(true)
    var data = {
      'type': 'free', 
      'id': store.getState().team.team.id,
      'meta': {
        'last_usage_reset': new Date(),
      }
    }
    await axios.post(account_url, data, configs)
      .then((r) => {
        setLoading(false);
        billingCallBack();
      })
      .catch((e) => {
        console.log(e)
        toast.error(e.message ? e.message : 'contact support')
        setLoading(false)
      })
  }


  if (key === null) {
    return null
  }

  const stripePromise = loadStripe(key);
  


  return (
    
    <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
      { ready && 
        <div className="flex-1 h-full max-w-lg mx-auto bg-white rounded-lg shadow-xl dark:bg-gray-800">

          { view === 'phone' && 
            <div className="p-6 sm:p-12 ">
                <h1 id='title' className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                  Phone Number
                </h1>
                <div>
                  <PhoneInput
                    containerStyle={{width: '100', }}
                    inputStyle={{width: '100%',}}
                    country={'us'}
                    value={phone}
                    onChange={num => setPhone(num)}
                  />
                  <Button 
                    type="submit" 
                    block 
                    className={`mt-4 ${(phone.length > 0 && !loading)? 'cursor-pointer' : 'cursor-not-allowed'}`} 
                    onClick={() => setView('team')} 
                    disabled={phone.length <= 0 || loading}
                  >
                    <Spinner display={loading}/>
                    Save
                  </Button>
                </div>
            </div>
          }
          
          { view === 'team' && 
            <div className="p-6 sm:p-12 ">
                <h1 id='title' className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                  New Team
                </h1>
                <div>
                  <Input 
                    className="rounded-md p-2 mt-1" 
                    onKeyDown={handleKeyDownTeam}
                    type="text"
                    placeholder="Team Name" 
                    value={teamName}
                    onChange={e => setTeamName(e.target.value)}/>
                  <Button 
                    type="submit" 
                    block 
                    className={`mt-4 ${(teamName.length > 0 && !loading)? 'cursor-pointer' : 'cursor-not-allowed'}`} 
                    onClick={createTeam} 
                    disabled={teamName.length <= 0 || loading}
                  >
                    <Spinner display={loading}/>
                    Create Team
                  </Button>
                </div>
            </div>
          }

          { view === 'member' && 
            <div className="p-6 sm:p-12 ">
                <h1 id='title' className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                  Invite Members
                </h1>
                <div>
                  {members.map((x, i) => {
                    return(
                      <div className='flex justify-start' key={i}>
                        <Input 
                          className="rounded-md p-2 mt-1 mr-1" 
                          onKeyDown={handleKeyDownMemebr}
                          type="text"
                          placeholder="jane@example.com" 
                          value={x.email}
                          name='email'
                          onChange={e => handleMemberChange(e, i)}
                        />
                        <div className="my-2" style={{ width: '5rem',}}>
                          {members.length !== 1 &&  <Button onClick={() => handleRemoveMember(i)} layout="link" size="icon" aria-label="Remove"><TrashIcon className="w-5 h-5" aria-hidden="true" /></Button>}
                          {members.length - 1 === i && <Button onClick={() => handleAddMember(i)} layout="link" size="icon" aria-label="Add"><PlusIcon className="w-5 h-5" aria-hidden="true" /></Button>}
                        </div>
                      </div>
                    )
                      
                  })}  
                  <Button 
                    type="submit" 
                    block 
                    className={`mt-8 ${(members[0]['email'].length > 0 && members[0]['email'].includes('@') && !loading) ? 'cursor-pointer' : 'cursor-not-allowed'}`} 
                    onClick={sendInvites} 
                    disabled={members[0]['email'].length <= 0 || !members[0]['email'].includes('@') || loading}
                  >
                    <Spinner display={loading}/>
                    Send Invites
                  </Button>
                  <p className='hover:text-blue-600 text-blue-500 mt-6 text-xs text-right cursor-pointer' onClick={() => setView('billing')}>Skip for now</p>
                </div>
            </div>
          }

          { view === 'billing' && 
            <div className="p-6 sm:p-12 ">
                
                <div className='flex justify-between mb-6'>
                  <div>
                    <h1 id='title' className="text-xl font-semibold text-gray-700 dark:text-gray-200">
                      Select Your Plan
                    </h1>
                    <div className='mt-2'>
                      <span onClick={() => setViewCoupon(!viewCoupon)} className='hover:text-blue-600 text-blue-500 my-4 text-xs cursor-pointer'>Enter Coupon</span>
                      <div>
                        {viewCoupon && 
                          <Input 
                            className='w-32 rounded h-8'
                            placeholder='FREE-24'
                            value={coupon}
                            onChange={(e) => {
                              setCoupon(e.target.value)
                              checkCoupon(e.target.value)
                            }}
                          />
                        }
                      </div>
                    </div>
                  </div>

                  <div className='flex justify-end'>
                    <div>
                      <span className='mr-2 my-auto text-[.7rem] text-gray-700 dark:text-gray-200 font-bold'>{interval === 'month' ? 'Monthly' : 'Annually (save 10%)'}</span>
                    </div>
                    <div>
                      <Toggle 
                        small 
                        id='interval' 
                        checked={interval === 'year'} 
                        onChange={() => {
                          var inter = null
                          if (interval === 'month'){
                            inter = 'year'
                          } else {
                            inter = 'month'
                          }
                          setInterval(inter)
                        }} 
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className='my-10 w-full'>
                    <div onClick={() => {setBillingInfo('free')}} className={`${plan === 'free' && 'ring-1 ring-blue-600'} cursor-pointer rounded-lg hover:-translate-y-1 ease-in-out duration-200 w-full grid grid-cols-3 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 px-4 py-3 my-3`}>
                      <div>
                        <span className='font-bold text-xs py-1 px-2 rounded-xl text-gray-600 bg-gray-200 dark:text-gray-100 dark:bg-gray-600'>FREE</span>
                      </div>
                      <div className='justify-self-center'>
                        <span className='font-semibold text-sm '>1 Site</span>
                      </div>
                      <div className='justify-self-end'>
                        {interval === 'month' ?
                          <span className='font-bold text-sm'>$0 /m</span>
                        :
                          <span className='font-bold text-sm'>$0 /m</span>
                        }
                      </div>
                    </div>
                    <div onClick={() => {setBillingInfo('basic')}} className={`${plan === 'basic' && 'ring-1 ring-blue-600'} cursor-pointer rounded-lg hover:-translate-y-1 ease-in-out duration-200 w-full grid grid-cols-3 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 px-4 py-3 my-3`}>
                      <div>
                        <span className='font-bold text-xs py-1 px-2 rounded-xl text-yellow-700 bg-yellow-200 dark:text-yellow-100 dark:bg-yellow-600'>BASIC</span>
                      </div>
                      <div className='justify-self-center'>
                        <span className='font-semibold text-sm'>5 Sites</span>
                      </div>
                      <div className='justify-self-end'>
                        {interval === 'month' ?
                          <span className='font-bold text-sm'>${89*discount} /m</span>
                        :
                          <span className='font-bold text-sm'>${80*discount} /m</span>
                        }
                      </div>
                    </div>
                    <div onClick={() => {setBillingInfo('pro')}} className={`${plan === 'pro' && 'ring-1 ring-blue-600'} cursor-pointer rounded-lg hover:-translate-y-1 ease-in-out duration-200 w-full grid grid-cols-3 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 px-4 py-3 my-3`}>
                      <div>
                        <span className='font-bold text-xs py-1 px-2 rounded-xl text-blue-600 bg-blue-200 dark:text-blue-100 dark:bg-blue-600'>PRO</span>
                      </div>
                      <div className='justify-self-center'>
                        <span className='font-semibold text-sm'>10 Sites</span>
                      </div>
                      <div className='justify-self-end'>
                        {interval === 'month' ?
                          <span className='font-bold text-sm'>${179*discount} /m</span>
                        :
                          <span className='font-bold text-sm'>${160*discount} /m</span>
                        }
                      </div>
                    </div>
                    <div onClick={() => {setBillingInfo('plus')}} className={`${plan === 'plus' && 'ring-1 ring-blue-600'} cursor-pointer rounded-lg hover:-translate-y-1 ease-in-out duration-200 w-full grid grid-cols-3 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 px-4 py-3 my-3`}>
                      <div>
                        <span className='font-bold text-xs py-1 px-2 rounded-xl text-teal-700 bg-teal-200 dark:text-teal-100 dark:bg-teal-600'>PLUS</span>
                      </div>
                      <div className='justify-self-center'>
                        <span className='font-semibold text-sm'>25 Sites</span>
                      </div>
                      <div className='justify-self-end'>
                        {interval === 'month' ?
                          <span className='font-bold text-sm'>${349*discount} /m</span>
                        :
                          <span className='font-bold text-sm'>${314*discount} /m</span>
                        }
                      </div>
                    </div>
                  </div>

                  {plan === 'free' ?
                    <div className='flex justify-end mt-3'>
                      <Button layout="outline" size="small" onClick={setFreeAccount} disabled={loading}>
                        <Spinner display={loading}/>
                        Submit
                      </Button>
                    </div>
                  :
                    <Elements stripe={stripePromise}>

                      <BillingOnboarding setupData={setupData} configs={configs} callBack={billingCallBack}/>

                    </Elements>
                  }
                 
                </div>
            </div>
          }

        </div>
      }
    </div>
   
  )
}

export default NewTeam
