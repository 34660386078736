import React, { useState, useEffect } from 'react';
import {Table, TableHeader, TableCell, TableBody, TableRow, TableFooter,
  TableContainer, Pagination,} from '@windmill/react-ui';
import Toggle from "../Toggle/Toggle.js";
import axios from 'axios';
import ScheduleModal from '../Modals/ScheduleModal';
import DeleteScheduleModal from '../Modals/DeleteScheduleModal';
import AutomationModal from '../Modals/AutomationModal';
import toast from 'react-hot-toast';
import Tooltip from '../Tooltip/Tooltip.js';
import Loader from '../Buttons/Loader.js';
import { clearAllListeners } from '@reduxjs/toolkit';

const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;

function SchedulesTable(props) {

  const emptySchedules = {results:[], count:0}
  const [schedules, setSchedules] = useState(emptySchedules);
  const [loading, setLoading] = useState(true)
  const getSchedulesUrl = `${BACKEND_URL}/v1/ops/schedule?${props.site ? 'site_id': 'page_id'}=${props.site ? props.site.id : props.page.id}`;


  const getData = async () => {
    await axios.get(getSchedulesUrl)
      .then((r) => {
        setLoading(false)
        setSchedules(r.data)
      })
      .catch((e) => {
        setSchedules(emptySchedules)
        setLoading(false)
        console.log(e)
      })
  }

  // !!!!! VERY IMPORTANT - this subscribes the component to listen for prop changes from the parent !!!!!!
  useEffect(() => {
    getData();
    return () => {
      getData;
    }
    // eslint-disable-next-line 
  }, [props?.schedules])

  

  // setup pages control for table
  const pageTable = 1

  // pagination setup
  const resultsPerPage = 10

  // pagination change control
  function onPageChangeTable(p) {
    if(p){
      var offSet = (p-1)*10
      var paginatedUrl = `${getSchedulesUrl}&limit=10&offset=${offSet}`
      axios.get(paginatedUrl)
        .then((r) => {
          setSchedules(r.data);
      })
    }
    
  }



  function getDate(schedule) {
    if(schedule.time_created){
        var date = new Date(schedule.time_created).toLocaleDateString();
        var formatedDateTime = `${date}`
        return formatedDateTime
    }else{
        return '––';
    }
    
  }

  function updateStatus(schedule) {
    var data = {'status': schedule.status, 'schedule_id': schedule.id};
    axios.post(`${BACKEND_URL}/v1/ops/schedule`, data)
    .then((r) => {
      props.getSchedules();
      toast.success('Schedule updated!')
    }).catch((err) => {
      console.log(err);
      toast.error('Opps, something went wrong...')
    })
  }


  if (loading){
    return <Loader item={'Schedules'}/>
  }


  return (
    <>
      <TableContainer className="mb-8 dark:bg-gray-800 bg-white">
        <h1 className="text-gray-600 dark:text-gray-300" style={{ margin: '.7em', fontSize: '1.3em', fontWeight: 600, }}>Schedules</h1>
        <Table>
          <TableHeader>
            <tr className='dark:bg-gray-800 bg-white'>
              <TableCell>Task</TableCell>
              <TableCell>Frequency</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </tr>
          </TableHeader>
          <TableBody>
            {schedules.results.slice((pageTable - 1) * resultsPerPage, pageTable * resultsPerPage).map((schedule, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <ScheduleModal site={props.site} page={props.page} schedule={schedule} getSchedules={props.getSchedules} type='text' text={schedule.task_type}/>
                  </TableCell>
                  <TableCell>
                    <span className="text-sm">{schedule.frequency} @{schedule.time}</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-sm">{getDate(schedule)}</span>
                  </TableCell>
                  <TableCell>
                    <Tooltip content={schedule.status === 'Active' ? 'pause schedule' : 'activate schedule'}>
                      <Toggle small id={schedule.id} checked={schedule.status==='Active' ? true : false} onChange={()=> updateStatus(schedule)} />
                    </Tooltip>
                    <label htmlFor={schedule.id} className="text-gray-600 dark:text-gray-300"></label>
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center space-x-4">
                      <Tooltip content='automation'><AutomationModal schedule={schedule} site={props.site} page={props.page} type='icon'/></Tooltip>
                      <Tooltip content='delete schedule'><DeleteScheduleModal schedule={schedule} getSchedules={props.getSchedules} type='icon'/></Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
        <TableFooter>
          <Pagination
            totalResults={schedules.count}
            resultsPerPage={resultsPerPage}
            onChange={onPageChangeTable}
            label="Table navigation"
          />
        </TableFooter>
      </TableContainer>
    </>
  )
}




export default SchedulesTable

