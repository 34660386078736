import React, { useState } from 'react';
import { Button } from '@windmill/react-ui';
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'
import { TrashIcon } from '../../icons';
import axios from "axios";
import toast from 'react-hot-toast';
import Spinner from '../Buttons/Spinner'




function DeleteScheduleModal(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/ops/schedule/'

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  const handleDeleteSchedule = (evt) => {
    evt.preventDefault();
    setIsLoading(true);

    axios.delete(`${url}${props.schedule.id}`)
    .then((res) => {
      if (res.data) {
        console.log(res.data);
        setIsLoading(false);
        setIsModalOpen(false);
        props.getSchedules();
        toast.success('Schedule deleted!');

        
      }
    }).catch((e) => {
      if (e.response) {
        toast.error(e.response.data.reason)
        setIsLoading(false);
      }
    })
      
  }

  const ButtonType = () => {
    if(props.type==='icon'){
      return(
          <Button onClick={openModal} layout="link" size="icon" aria-label="Delete">
            <TrashIcon className="w-5 h-5" aria-hidden="true" />
          </Button>
      )
    }else if(props.type==='button'){
      return (
        <Button layout="outline" iconRight={TrashIcon} onClick={openModal}>
            <span>Delete Schedule</span>
        </Button>
      )
    }
  }



  return (
    <>

      {ButtonType()}

      <Modal isOpen={isModalOpen} onClose={closeModal} title={'Delete Schedule'}>
        <ModalBody>
          <h1>Are you sure you want to delete this schedule?</h1>
        </ModalBody>
        <ModalFooter>

          <div className="hidden sm:block">
            <Button layout="outline" onClick={handleDeleteSchedule} disabled={isLoading}>
              <Spinner display={isLoading}/>
              Confirm Delete
            </Button>
          </div>
          <div className="block w-full sm:hidden">
            <Button block size="large" layout="outline" onClick={handleDeleteSchedule} disabled={isLoading}>
            <Spinner display={isLoading}/>
              Confirm Delete
            </Button>
          </div>
        
        </ModalFooter>
      </Modal>
    </>
  )
}

export default DeleteScheduleModal
