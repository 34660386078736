import PageTitle from '../components/Typography/PageTitle';
import BillingPlanCard from '../components/Cards/BillingPlanCard';
import InvoicesTable from '../components/Tables/InvoicesTable';
import CancelSubModal from '../components/Modals/CancelSubModal';
import store from '../store';
import BillingMethodCard from '../components/Cards/BillingMethodCard';





function Billing() {

  const member = store.getState().member.member



  return (
    <>
      <PageTitle>Billing</PageTitle>
        {member.type === 'admin' ?
          <>

            <div className='grid md:grid-cols-2 gap-6'>
              <BillingPlanCard/>
              <BillingMethodCard/>
            </div>

            <div className='my-12'>
              <InvoicesTable />
            </div>
            <div className='mb-12 text-right'>
              <h3 className='text-xl font-bold dark:text-gray-300 text-gray-600 my-4'>Manage Subscription</h3>
              <CancelSubModal type={'button'} />
            </div>
          </>
          
          :
          <p className='text-lg mt-2 ml-1 text-gray-600 dark:text-gray-300 font-semibold'>
            Managed by your Admin
          </p>
        }
    </>
  )
}


export default Billing
