import Page404 from '../pages/404'
import InternalApi from '../pages/InternalApi'
import Billing from '../pages/Billing'
import BillingUpdate from '../pages/BillingUpdate'
import SiteDetail from '../pages/SiteDetail'
import PageDetail from '../pages/PageDetail'
import ScanDetail from '../pages/ScanDetail'
import TestDetail from '../pages/TestDetail'
import SlackConfirm from '../store/auth/SlackConfirm'
import Home from '../pages/Home'
import Account from '../pages/Account'
import Logs from '../pages/Logs'
import Report from '../pages/Report'
import Migration from '../pages/Migration'
import TestcaseDetail from '../pages/TestcaseDetail'
import Cases from '../pages/Cases'
import CaseDetail from '../pages/CaseDetail'
import Issues from '../pages/Issues'
import IssueDetail from '../pages/IssueDetail'
import IssueCreate from '../pages/IssueCreate'




/**
 * ⚠ These are internal routes!
 * They will be rendered inside the app, using the default `containers/Layout`.
 * If you want to add a route to, let's say, a landing page, you should add
 * it to the `App`'s router, exactly like `Login`, `CreateAccount` and other pages
 * are routed.
 *
 * If you're looking for the links rendered in the SidebarContent, go to
 * `routes/sidebar.js`
 */
const routes = [
  {
    path: '',
    component: <Home/>, 
  },
  {
    path: 'internal-api',
    component: <InternalApi/>,
  },
  {
    path: 'logs',
    component: <Logs/>,
  },
  {
    path: 'account',
    component: <Account/>,
  },
  {
    path: 'billing',
    component: <Billing/>, 
  },
  {
    path: 'billing/update', 
    component: <BillingUpdate/>,
  },
  {
    path: '404',
    component: <Page404/>,
  },
  {
    path: 'site/:id',
    component: <SiteDetail/>,
  },
  {
    path: 'page/:id',
    component: <PageDetail/>,
  },
  {
    path: 'page/:id/report',
    component: <Report/>, 
  },
  {
    path: 'site/:id/migration',
    component: <Migration/>, 
  },
  {
    path: 'scan/:id',
    component: <ScanDetail/>,
  },
  {
    path: 'test/:id',
    component: <TestDetail/>,
  },
  {
    path: 'case',
    component: <Cases/>,
  },
  {
    path: 'case/:id',
    component: <CaseDetail/>,
  },
  {
    path: 'testcase/:id',
    component: <TestcaseDetail/>,
  },
  {
    path: 'issue',
    component: <Issues/>,
  },
  {
    path: 'issue/:id',
    component: <IssueDetail/>,
  },
  {
    path: 'issue/new',
    component: <IssueCreate/>,
  },
  {
    path: 'slack',
    component: <SlackConfirm/>,
  },
]

export default routes
