import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'
import SectionTitle from '../Typography/SectionTitle'
import {Table, TableHeader, TableCell, TableBody, TableRow, TableFooter,
  TableContainer, Badge, Pagination, Input} from '@windmill/react-ui'
import DeleteSiteModal from '../Modals/DeleteSiteModal'
import siteDataSlice from "../../store/slices/siteData";
import axios from 'axios'
import store from '../../store';
import ScanModal from '../Modals/ScanModal';
import TestModal from '../Modals/TestModal';
import TestcaseModal from '../Modals/TestcaseModal';
import Tooltip from '../Tooltip/Tooltip';
import Gear from '../Buttons/Gear';
import ScheduleModal from '../Modals/ScheduleModal';
import Loader from '../Buttons/Loader';






function SiteTable() {
  
  const [siteIds, setSiteIds] = useState([]);
  const [collecting, setCollecting] = useState(false);
  const [allSites, setAllSites] = useState(false)
  const [offSet, setOffSet] = useState(0)

  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const getSitesUrl = `${BACKEND_URL}/v1/ops/site?limit=10&offset=${offSet}`;


  // setup pages control for table
  const pageTable = 1

  // pagination setup
  const resultsPerPage = 10

  // connecting table to redux store
  const siteData = useSelector(state => state.siteData.siteData);


  // setting generic getData 
  const getData = async () => {
    await axios.get(getSitesUrl).then((r) => {
      store.dispatch(siteDataSlice.actions.setSiteData(r.data));
    });
  }

  // setting auto refresh for siteData
  useEffect(() => {
    const interval = setInterval(() => {
      getData();
    }, 20000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [offSet]);


  // pagination change control
  function onPageChangeTable(p) {
    if(p){
      setOffSet(((p-1)*10))
      let _offset = (p-1)*10
      let paginatedUrl = `${BACKEND_URL}/v1/ops/site?limit=10&offset=${_offset}`
      axios.get(paginatedUrl)
        .then((r) => {
          store.dispatch(siteDataSlice.actions.setSiteData(r.data));
      }).catch(err => console.log(err))
    }
    
  }


  const handleCheckBox = (id) => {
    setAllSites(false)
    if (siteIds.includes(id)){
      let ids = [...siteIds]
      let newIds = ids.filter(i => i !== id)
      setSiteIds([...newIds])
    }else{
      setSiteIds([...siteIds, id])
    }
  }


  const handleSelectAll = async () => {
    if (siteIds.length === 0){
      // get all sites in account
      let offSet = 0
      let allIds = []
      let next = true
      setCollecting(true)
      
      while (next){
        let paginatedUrl = `${BACKEND_URL}/v1/ops/site?limit=100&offset=${offSet}`
        await axios.get(paginatedUrl)
          .then((r) => {
            for (let i in r.data.results){
              allIds.push(r.data.results[i].id)
            }
            if (r.data.next){
              offSet = offSet + 100
            }else{next = false}
        }).catch(err => console.log(err))
      }
      
      setSiteIds([...allIds])
      setAllSites(true)
      setCollecting(false)

    }else{
      setSiteIds([])
      setAllSites(false)
    }
  }

  const deleteCallBack = () => {
    setSiteIds([])
    setAllSites(false)
  }


  function getHealthStatus(site) {
    let scan = site.info.latest_scan
    let badge = 'neutral';
    let health = 'no data';
    let value = health;
    let score = scan.score
    
    if (score !== 0 && score !== null){
      score = score < 100 ? Number(score.toFixed(1)) : score
      if (score >= 75) {
        health = 'Good';
        badge = 'success';
      } else if (score >= 60 && score < 75) {
        health = 'Okay';
        badge = 'warning';
      } else {
        health = 'Poor';
        badge = 'danger';
      }
      value = health+' - '+score+'%'
    }

    if (scan.time_completed === null){
      value = 'working'
      badge = 'neutral'
    }
    
    return (
      <Badge type={badge}>
        {value} 
        { value === 'working' && 
          <Gear size={'ml-2 h-4 w-4 mt-[.1rem]'} color={'dark:text-gray-200 text-gray-600'}/>
        }
      </Badge> 
    )
  }




  function getDate(timestamp) {
    if(timestamp){
      let date = new Date(timestamp).toLocaleDateString()
      if (date === 'Invalid Date'){
        date = timestamp.substring(0, 10)
      }
      return date;
    }else{
      return '-';
    }
  }

  function getTestScore(site) {
    let test = site.info.latest_test
    let badge = 'neutral';
    let health = 'no data';
    let value = health;
    let date = getDate(test.time_created)
    if(test.score!==null){
        let score = test.score < 100 ? Number(test.score.toFixed(2)) : test.score
        if (test.status === 'passed'){
          health = 'Passed'
          badge = 'success'
        } else if (test.status === 'failed') {
          health = 'Failed'
          badge = 'danger'
        }
        // value = health+' - '+score+'%';
        value = health;
        
    }
    if (test.id){
      if (test.time_completed === null){
        value = 'working'
        badge = 'neutral'
      }
    }
    return(
      <div className='flex justify-start'>
        <div className='mr-3'>
          <Badge type={badge}>
            {value} 
            {value === 'working' && 
              <Gear size={'ml-2 h-4 w-4 mt-[.1rem]'} color={'dark:text-gray-200 text-gray-600'}/>
            }
          </Badge>
        </div>
        <div className='text-sm my-auto'>
            <span>{date}</span>
        </div>
      </div>
    )
  }

  if (siteData===null){
    return <Loader item={'Sites'} />
  }


  return (
    <>
      {siteIds.length > 0 &&
        <div className='flex justify-start'>
          <div className='mr-4'>
            <DeleteSiteModal ids={siteIds} type='button' callBack={deleteCallBack}/>
          </div>
          <div className='mr-4'>
            <ScheduleModal siteIds={siteIds} type='button'/>
          </div>
          <div className='mr-4'>
            <ScanModal siteIds={siteIds} callBack={getData} type='button'/>
          </div>
          <div className='mr-4'>
            <TestModal siteIds={siteIds} callBack={getData} type='button'/>
          </div>
        </div>
      }
      <SectionTitle></SectionTitle>
      <TableContainer className="mb-8 dark:bg-gray-800 bg-white">
        <Table>
          <TableHeader>
            <tr className='dark:bg-gray-800 bg-white'>
              <TableCell>
                <Input 
                  className='rounded-sm'
                  type="checkbox"
                  value={allSites} 
                  checked={allSites}
                  onChange={handleSelectAll}
                  disabled={collecting}
                />
              </TableCell>
              <TableCell>Site</TableCell>
              <TableCell>Health</TableCell>
              <TableCell>Last Scan</TableCell>
              <TableCell>Last Test</TableCell>
              <TableCell>Actions</TableCell>
            </tr>
          </TableHeader>
          <TableBody>
            {siteData.results.slice((pageTable - 1) * resultsPerPage, pageTable * resultsPerPage).map((site, i) => (
              <TableRow key={i}>
                <TableCell>
                  <div>
                      <Input 
                        className='rounded-sm'
                        type="checkbox"
                        value={siteIds.includes(site.id)} 
                        checked={siteIds.includes(site.id)}
                        onChange={() => handleCheckBox(site.id)}
                        disabled={collecting}
                      />
                    </div>
                </TableCell>
                <TableCell>
                  <div style={{ maxWidth: '30rem' }} className='overflow-x-auto'>
                      <Link to={`/site/${site.id}`}><p className="font-semibold">{site.site_url}</p></Link>
                  </div>
                </TableCell>
                <TableCell>
                  {getHealthStatus(site)}
                </TableCell>
                <TableCell>
                  <span className="text-sm">{getDate(site.info.latest_scan.time_created)}</span>
                </TableCell>
                <TableCell>
                    {getTestScore(site)}
                </TableCell>
                <TableCell>
                  <div className="flex items-center space-x-2">
                    <Tooltip content='scan site'><ScanModal site={site} callBack={getData} type='icon'/></Tooltip>
                    <Tooltip content='test site'><TestModal site={site} callBack={getData} type='icon'/></Tooltip>
                    <Tooltip content='run testcase'><TestcaseModal site={site} type='icon'/></Tooltip>
                    <Tooltip content='delete site'><DeleteSiteModal site={site} callBack={getData} type='icon'/></Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TableFooter className='dark:bg-gray-800 bg-white'>
          <Pagination
            totalResults={siteData.count}
            resultsPerPage={resultsPerPage}
            onChange={onPageChangeTable}
            label="Table navigation"
          />
        </TableFooter>
      </TableContainer>
    </>
  )
}




export default SiteTable
