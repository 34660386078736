import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom';
import {Table, TableHeader, TableCell, TableBody, TableRow, TableFooter,
  TableContainer, Pagination, Badge, Input} from '@windmill/react-ui'
import DeleteCaseModal from '../Modals/DeleteCaseModal';
import CopyCaseModal from '../Modals/CopyCaseModal';
import axios from 'axios'
import Tooltip from '../Tooltip/Tooltip';
import Loader from '../Buttons/Loader';
import TestcaseModal2 from '../Modals/TestcaseModal2';

const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;

function CasesTable(props) {

  const [cases, setCases] = useState(props.cases ? props.cases : null)
  const [query, setQuery] = useState('')
  const getCasesUrl = `${BACKEND_URL}/v1/ops/case`;
  const searchCaseUrl = BACKEND_URL + '/v1/ops/case/search?query='
  

  const getData = useCallback(() => {
    axios.get(getCasesUrl).then((r) => {
      setCases(r.data);
    });
    // eslint-disable-next-line
  }, [getCasesUrl])
  
  useEffect(() => {
    getData();
    // eslint-disable-next-line 
  }, [getData, props?.proc]);

  const search = async () => {
    if (query !== '') {
        await axios.get(`${searchCaseUrl}${query}`)
            .then((r) => {
                setCases(r.data)
            })
            .catch((e) => {
                console.log(e)
            })
    } else {
        // console.log(query)
    }
  }

  // setup pages control for table
  const pageTable = 1

  // pagination setup
  const resultsPerPage = 10

  // pagination change control
  function onPageChangeTable(p) {
    if(p){
      var offSet = (p-1)*5
      // var paginatedUrl = `${BACKEND_URL}/v1/ops/case?limit=10&offset=${offSet}`
      var paginatedUrl = cases.next ? (query.length > 0 ? `${cases.next.split('offset')[0]}offset=${offSet}&query=${query}` : `${cases.next.split('offset')[0]}offset=${offSet}` ) : `${BACKEND_URL}/v1/ops/case?limit=10&offset=${offSet}`
      axios.get(paginatedUrl)
        .then((r) => {
          setCases(r.data);
      })
    }
    
  }




  function getDate(cas) {
    if(cas.time_created){
        var date = new Date(cas.time_created).toLocaleDateString();
        var time = new Date(cas.time_created).toLocaleTimeString('en-US');
        var formatedDateTime = `${date} ${time}`
        return formatedDateTime
    }else{
        return '––';
    }
    
  }

  if (cases===null){
   return <Loader item={'Cases'}/>
  }


  return (
    <>
      <TableContainer className="mb-8 dark:bg-gray-800 bg-white">
        <div className='flex justify-between w-full py-4 px-3'>
          <Input
            className='max-w-md h-8 rounded outline-none border-0 bg-gray-100 dark:bg-gray-900'
            value={query}
            placeholder='search by name or associated site'
            onChange={(e) => {
              setQuery(e.target.value)
              if (e.target.value !== '') {
                search();
              } else {
                getData();
              }
            }}
          />
        </div>
        <Table>
          <TableHeader>
            <tr className='dark:bg-gray-800 bg-white'>
              <TableCell>Name</TableCell>
              <TableCell>Site</TableCell>
              <TableCell>Steps</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Actions</TableCell>
            </tr>
          </TableHeader>
          <TableBody>
            {cases.results.slice((pageTable - 1) * resultsPerPage, pageTable * resultsPerPage).map((cas, i) => (
              <TableRow key={i} className="hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer">
                <TableCell>
                  <Link to={`/case/${cas.id}`}>
                    <span className='font-semibold text-gray-600 dark:text-gray-400'>{cas.name}</span>
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/case/${cas.id}`}>
                    {cas.site_url}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/case/${cas.id}`}>
                    {cas.steps.num_steps}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/case/${cas.id}`}>
                    <Badge type={cas.type === 'recorded' ? 'primary' : 'neutral'}>{cas.type}</Badge>
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/case/${cas.id}`}>
                    <span className="text-sm">{getDate(cas)}</span>
                  </Link>
                </TableCell>
                <TableCell>
                  <div className='flex justify-start gap-2'>
                    <Tooltip content='copy case'><CopyCaseModal case={cas} type='icon'/></Tooltip>
                    <Tooltip content='run testcase'><TestcaseModal2 case={cas} type='icon'/></Tooltip>
                    <Tooltip content='delete case'><DeleteCaseModal type='icon' callBack={getData} case={cas}/></Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TableFooter>
          <Pagination
            totalResults={cases.count}
            resultsPerPage={resultsPerPage}
            onChange={onPageChangeTable}
            label="Table navigation"
          />
        </TableFooter>
      </TableContainer>
    </>
  )
}




export default CasesTable

