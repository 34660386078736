import React, {useState, useEffect} from 'react'
import PageTitle from '../components/Typography/PageTitle'
import CaseCard from '../components/Cards/CaseCard';
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Loader from '../components/Buttons/Loader';
import BackButton from '../components/Buttons/BackButton';
import DeleteCaseModal from '../components/Modals/DeleteCaseModal';




function CaseDetail() {
    
    const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
    const casesUrl = `${BACKEND_URL}/v1/ops/case`;
    const siteUrl = `${BACKEND_URL}/v1/ops/site`;
    const [thiscase, setThisCase] = useState(null);
    const [site, setSite] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();

    const loadData = async () => {
        // get case info
        if (id){
            await axios.get(`${casesUrl}/${id}`)
            .then((r) => {
                setThisCase(r.data)
                if (r.data.site){
                    axios.get(`${siteUrl}/${r.data.site}`)
                        .then((r) => {
                            setSite(r.data)
                        })
                        .catch((err) => {
                            console.log(err)
                
                        });
                } else {
                    setSite(false)
                }
                
            })
            .catch((err) => {
                console.log(err)
    
            });
        }
        
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line 
    },[])

    
    const navBack = () => {
        window.location.href = '/case'
    }

    const navToSite = (url) => {
        window.open(url, "_blank");
    }



    if (thiscase === null || site === null) {
        return <Loader item={'Case Data'}/>
    }


    return (
        <>
            <div className='flex justify-between'>
                <PageTitle>
                    <div className="flex mb-2">
                        <div>
                            <div className='flex justify-start '>
                                <div>
                                    <span className='text-center px-2 py-1 rounded-full text-[11px] font-semibold bg-gray-300 dark:bg-gray-500 dark:text-white text-gray-700'>
                                        CASE
                                    </span>
                                </div>
                                <h1 className='font-semibold text-gray-400 dark:text-gray-500 text-[.9rem] ml-3 mt-1'>
                                    {new Date(thiscase.time_created).toLocaleDateString()}
                                </h1>
                            </div>
                            <div className='flex justify-start'><div className='my-2 text-[1.3rem] p-2 px-4 dark:bg-gray-800 bg-gray-200 rounded-md text-center w-auto'><pre><code>{thiscase.name}</code></pre></div></div>
                            {site && <h3 onClick={() => navigate(`/site/${site ? site.id : ''}`)} className='text-[1.2rem] hover:underline cursor-pointer'>{site ? site.site_url : ''}</h3> }
                            <div className='flex justify-start mt-2'>
                                <div className='md:hidden block -mt-[.3rem]'>
                                    <DeleteCaseModal type='icon' case={thiscase} callBack={navBack}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </PageTitle> 
                <div className='mt-8 md:block hidden ml-1'>
                    <DeleteCaseModal type='button' callBack={navBack} case={thiscase}/>
                </div>
                
            </div>
            
            <BackButton />
            
            <div className="mb-8 overflow-y-clipped">

                <CaseCard callBack={loadData} case={thiscase} site={site}/>
                
            </div>
        </>
    )
}

export default CaseDetail