import React, {useEffect, useState} from 'react'
import { Card, CardBody, Badge} from '@windmill/react-ui';
import Gear from '../Buttons/Gear';




function StepCard (props) {

    const [step, setStep] = useState(props.step)
    const [stepIndex, setStepIndex] = useState(props.stepIndex)


    useEffect(() => {
      setStep(props.step)
      // eslint-disable-next-line
    }, [props?.step])

    useEffect(() => {
        setStepIndex(props.stepIndex)
        // eslint-disable-next-line
    }, [props?.stepIndex])



    const getStepStatus = (step) => {
        var status = 'queued'
        var type = 'neutral'
        if(step.time_created !== null){
            status = 'working'
            type = 'primary'
        }
        if (step.passed === false){
            status = 'failed'
            type = 'danger'
        }
        if (step.passed === true){
            status = 'passed'
            type = 'success'
        }

        return <Badge type={type}>{status} {status === 'working' && <Gear size={'ml-2 h-4 w-4 mt-[.1rem]'} color={'dark:text-gray-200 text-gray-600'}/>}</Badge>
      }


    function getDate(timestamp) {
        var formatedDateTime = '-'
        if (timestamp !== null){
            var date = new Date(timestamp).toLocaleDateString();
            var time = new Date(timestamp).toLocaleTimeString('en-US');
            if (date === 'Invalid Date'){
                date = timestamp.substring(0, 10)
                time = timestamp.substring(11, 19) 
              }
            formatedDateTime = `${date} ${time}`
        }
       
        return formatedDateTime

    }

    if (!step){return null}

    return (

        <div className='max-h-[80vh] overflow-auto min-w-0 rounded-lg shadow-xs bg-white dark:bg-gray-800 p-4'>
            <h1 className='text-lg pl-4 pt-4 font-bold dark:text-gray-300'>Step #{stepIndex + 1}</h1>
            <div className='my-4 rounded-r-md px-2 py-2 bg-gray-100 dark:bg-gray-700 w-full' >
                <div className='px-6'>
                    <div className='flex justify-between'>
                        <h3 className='mt-2 font-semibold text-sm mb-2 dark:text-gray-300 my-auto'>
                            Action type: <span className='font-normal ml-1'>"{step.action.type}"</span>
                        </h3>
                        <div className='mt-2'>
                            {getStepStatus(step.action)}
                        </div>
                    </div>
                    <div className='my-2 text-sm font-semibold dark:text-gray-300'>
                        <p>started: <span className='ml-4 text-xs dark:text-gray-300'>{getDate(step.action.time_created)}</span></p>
                        <p>ended: <span className='ml-4 text-xs dark:text-gray-300'>{getDate(step.action.time_completed)}</span></p>
                    </div>
                    <div className='my-2 text-sm font-semibold dark:text-gray-300 overflow-auto whitespace-pre-wrap pb-6'>
                        {step.action.path !== '' && <p>path: <span className='ml-4 text-xs dark:text-gray-300'>"{step.action.path}"</span></p>}
                        {step.action.key !== '' && <p>key: <span className='ml-4 text-xs dark:text-gray-300'>"{step.action.key}"</span></p>}
                        {step.action.element !== '' && <div className='flex justify-start'><p>element: </p><pre><code className="ml-4 text-gray-600 dark:text-gray-100 text-xs">{step.action.element}</code></pre></div>}
                        {step.action.value !== '' && <p>value: <span className='ml-4 text-xs dark:text-gray-300'>{step.action.value}</span></p>}
                    </div>
                    <div className='mt-6 font-semibold text-sm mb-2 dark:text-gray-300 my-auto'>
                        {step.action.exception && 
                            <div className='overflow-auto whitespace-pre-wrap pb-6'> 
                                <p className='underline'>Exception: </p>
                                <pre>
                                    <code className="text-gray-600 dark:text-gray-100 text-xs">
                                        {step.action.exception}
                                    </code>
                                </pre>
                            
                            </div>
                        }
                        {step.action.image &&

                            <div className='my-4 pb-6'> 
                                <a href={step.action.image} target="_blank" className='cursor-pointer'>
                                    <img src={step.action.image} className='h-auto'/>
                                </a>
                            </div>

                        }
                    </div>
                </div>
            </div>
            {step.assertion.type !== '' &&
                <div className='my-4 rounded-r-md px-2 py-2 bg-gray-100 dark:bg-gray-700 w-full'>
                    <div className='px-6'>
                        <div className='flex justify-between'>
                            <h3 className='mt-2 font-semibold text-sm mb-2 dark:text-gray-300 my-auto'>
                                Assertion type: <span className='font-normal ml-1'>"{step.assertion.type !== '' ? step.assertion.type : 'null'}"</span>
                            </h3>
                            <div className='mt-2'>
                                {step.assertion.type !== '' && getStepStatus(step.assertion) }
                            </div>
                        </div>
                        <div className='text-sm font-semibold dark:text-gray-300'>
                            <p>started: <span className='ml-4 text-xs dark:text-gray-300'>{step.assertion.time_created ? getDate(step.assertion.time_created) : 'null'}</span></p>
                            <p>ended: <span className='ml-4 text-xs dark:text-gray-300'>{step.assertion.time_created ? getDate(step.assertion.time_completed) : 'null'}</span></p>
                        </div>
                        <div className='my-2 text-sm font-semibold dark:text-gray-300 overflow-auto whitespace-pre-wrap pb-6'>
                            {step.assertion.element !== '' && <div className='flex justify-start'><p>element: </p><pre><code className="ml-4 text-gray-600 dark:text-gray-100 text-xs">{step.assertion.element}</code></pre></div>}
                            {step.assertion.value !== '' && <p>value: <span className='ml-4 text-xs dark:text-gray-300'>{step.assertion.value}</span></p>}
                        </div>
                        <div className='mt-6 font-semibold text-sm mb-2 dark:text-gray-300 my-auto'>
                            {step.assertion.exception && 
                                <div className='overflow-auto whitespace-pre-wrap pb-6'> 
                                    <p className='underline'>Exception: </p>
                                    <pre>
                                        <code className="text-gray-600 dark:text-gray-100 text-xs">
                                            {step.assertion.exception}
                                        </code>
                                    </pre>
                                
                                </div>
                            }
                            {step.assertion.image &&

                                <div className='my-4 pb-6'> 
                                    <a href={step.assertion.image} target="_blank" className='cursor-pointer'>
                                        <img src={step.assertion.image} className='h-auto'/>
                                    </a>
                                </div>

                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}


export default StepCard