import React, { useContext, Suspense, useEffect } from 'react';
import {Routes, Route, useLocation } from 'react-router-dom';
import routes from '../routes';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Main from '../containers/Main';
import ThemedSuspense from '../components/ThemedSuspense';
import { SidebarContext } from '../context/SidebarContext';
import Page404 from '../pages/404';
import refreshAuthLogic from '../store/auth/RefreshToken'
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import getCookie from '../store/auth/GetCookie';
import GetToken from '../store/auth/GetToken';


// auth configs
const csrftoken = getCookie('csrftoken');
const token = GetToken();
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

axios.defaults.headers.common['Authorization'] = token;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['X-CSRFToken'] = csrftoken;
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.REACT_APP_BASE_FRONTEND_URL;

// refresh inteceptor
createAuthRefreshInterceptor(axios, refreshAuthLogic);






function Layout() {

  const { isSidebarOpen, closeSidebar } = useContext(SidebarContext)
  let location = useLocation()


  useEffect(() => {
    closeSidebar()
    // eslint-disable-next-line
  }, [location])


  return (
    <div
      className={`flex h-screen bg-gray-100 dark:bg-gray-900 overscroll-none ${isSidebarOpen && 'overflow-hidden'}`}
    >
      <Sidebar />

      <div className="flex flex-col flex-1 w-full" >
        <Header/>
        <Main>
          <Suspense fallback={<ThemedSuspense />}>
            <Routes>
              {routes.map((route, i) => {
                return route.component ? (
                  <Route
                    key={i}
                    exact
                    path={`/${route.path}`}
                    // render={(props) => <route.component {...props} />}
                    element={route.component}
                  />
                ) : null
              })}
              <Route element={<Page404/>} />
            </Routes>
          </Suspense>
        </Main>
      </div>
    </div>
  )
}

export default Layout
