import React, { useState, useEffect } from 'react';
import { Input, Button, Select, Textarea,} from '@windmill/react-ui';
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'
import axios from "axios";
import toast from 'react-hot-toast';
import { CodeIcon, TrashIcon, PlusIcon } from '../../icons';
import Spinner from '../Buttons/Spinner'





function AutomationModal(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/ops/automation'

  const site_id = props.site ? props.site.id : null
  const page_id = props.page ? props.page.id : null
  const automation_id = props.schedule.automation ? props.schedule.automation : ''
  const [name, setName] = useState(props.automation ? props.automation.name : '');
  const schedule = props.schedule ? props.schedule : ''
  const [expressions, setExpressions] = useState(props.automation ? props.automation.expressions : [{ joiner: '', data_type: '', operator: '', value: '', },]);
  const [actions, setActions] = useState(props.automation ? props.automation.actions : [{action_type: '', url: '', request: '', json: '', email: '', phone: '',},]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if ((props.schedule.automation || props.automation) && !isModalOpen){
      axios.get(`${url}/${ props.schedule.automation ? props.schedule.automation : props.automation.id}`)
      .then((r) => {
        setName(r.data.name);
        setExpressions(r.data.expressions);
        setActions(r.data.actions);
      }).catch((e) => {
        console.log(e);
      })
    } else if (props.schedule.task_type === 'testcase' && !props.automation){
      setExpressions([
        {
          joiner: '', 
          data_type: 'testcase', 
          operator: '==', 
          value: 'True',
        }
      ])
    }
    // eslint-disable-next-line
  },[props?.schedules, props, url])


  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  // handle input change
  const handleExpChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...expressions];
    list[index][name] = value;
    setExpressions(list);
  };
  const handleRemoveExp = index => {
    const list = [...expressions];
    list.splice(index, 1);
    setExpressions(list);
  };

  // handle input change
  const handleActChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...actions];
    list[index][name] = value;
    setActions(list);
  };
  const handleRemoveAct = index => {
    const list = [...actions];
    list.splice(index, 1);
    setActions(list);
  };

  // handle click event of the Add button
  const handleAddExp = () => {
    setExpressions([...expressions, { joiner: 'and', data_type: '', operator: '', value: '', }]);
  };
  const handleAddAct = () => {
    setActions([...actions, { action_type: '', url: '', request: 'POST', json: '', email: '', phone: '', }]);
  };

  const handleAddAutomation = (evt) => {
    evt.preventDefault();
    const data = {
      "site_id": site_id,
      "page_id": page_id,
      "name": name,
      "schedule_id": schedule.id, 
      "expressions": expressions,
      "actions": actions,
      'automation_id': automation_id,
    }

    setIsLoading(true);

    axios.post(`${url}`, data)
    .then((res) => {
      if (res.data) {

        // props.getAutomations();
        setIsLoading(false);
        setIsModalOpen(false);
        toast.success(props.schedule.automation ? 'Automation updated!' : 'Automation created!');
      }
    })
    .catch((e) => {
      if (e.response) {
        toast.error(e.response.data.reason)
        setIsLoading(false);
      }
    })
  }

  function clearModal() {
    setExpressions([{ joiner: '', data_type: '', operator: '', value: '', }]);
    setActions([{ action_type: '', url: '', request: 'POST', json: '', email: '', phone: '', }]);
  }


  const ButtonType = () => {
    if(props.type==='icon'){
      return(
          <Button onClick={openModal} layout="link" size="icon" aria-label="Edit">
            <CodeIcon className="w-5 h-5" aria-hidden="true" />
          </Button>
      )
    }else if(props.type==='button'){
      return (
        <div>
          <Button onClick={openModal} iconRight={CodeIcon} layout='outline'>
            { !props.schedule.automation ? 'Add Automation' : 'Update Automation'}
          </Button>
        </div>
      )
    }
  }



  return (
    <>

      {ButtonType()}

      <Modal 
        isOpen={isModalOpen} 
        onClose={closeModal} 
        style={{ maxHeight: '100vh', position: 'absolute', overflow: 'scroll', display:'block'}}
        title={!props.schedule.automation ? 'Add an Automation' : 'Update Automation'}
      >
        <ModalBody>
        <div className='overflow-auto' style={{ maxHeight: '30rem' }}>
          <div className='' >
            <h1 className="text-gray-600 dark:text-gray-300" style={{ fontSize: '1em',}}>Actions: </h1>
            <div className="mt-3">
              {actions.map((x, i) => {
                return (
                  <div className="flex gap-2" key={i}>
                    <Select className="rounded-md p-2 mt-1" 
                      style={{ width: '11rem', maxHeight: '3em'}}
                      name="action_type"
                      value={x.action_type}
                      onChange={e => handleActChange(e, i)}
                    >
                      <option value="">–––</option>
                      {schedule.task_type !== 'report' && <option value="slack">Alert on Slack</option>}
                      <option value="email">Send an Email</option>
                      {schedule.task_type !== 'report' && <option value="webhook">Send a Webhook</option>}
                      {schedule.task_type !== 'report' && <option value="phone">Send a Text</option>}
                    </Select>
                    <div className="flex gap-2" style={{ display: x.action_type === 'webhook' ? 'block' : 'none', }}  >
                      <Select className="rounded-md p-2 mt-1" 
                        style={{ width: '6rem',}}
                        name="request"
                        value={x.request}
                        onChange={e => handleActChange(e, i)}
                      >
                        <option value="GET">GET</option>
                        <option value="POST">POST</option>
                      </Select>
                      <Input
                        className="rounded-md p-2 mt-1"
                        style={{ width: '15rem',}}
                        placeholder='https://example.com/v1/api'
                        name="url"
                        value={x.url}
                        onChange={e => handleActChange(e, i)}
                      />
                      <code>
                        <Textarea className="rounded-md p-2 mt-1 whitespace-pre" rows="3" placeholder='{ "response": "test score is low"}' 
                          name="json"
                          value={x.json}
                          style={{ display: x.request === 'POST' ? 'block' : 'none', width: '15rem',}}
                          onChange={e => handleActChange(e, i)}/>
                      </code>
                    </div>
                    <div className="flex gap-2" style={{ display: x.action_type === 'email' ? 'block' : 'none', }}  >
                      <Input
                        className="rounded-md p-2 mt-1"
                        style={{ width: '12rem',}}
                        placeholder='jane.doe@example.com'
                        name="email"
                        value={x.email}
                        onChange={e => handleActChange(e, i)}
                      />
                    </div>
                    <div className="flex gap-2" style={{ display: x.action_type === 'phone' ? 'block' : 'none', }}  >
                      <div>
                        <Input
                          className="rounded-md p-2 mt-1"
                          style={{ width: '12rem',}}
                          placeholder='+13335558888'
                          name="phone"
                          value={x.phone}
                          onChange={e => handleActChange(e, i)}
                        />
                        <span className="ml-2 text-xs">
                          you are opting-in for <a href="https://scanerr.io/privacy#sms-policy" target='_blank' className="underline decoration-blue-600">SMS alerts</a>
                        </span>
                      </div>
                    </div>
                    <div className="mt-3" style={{ width: '5rem',}}>
                      {actions.length !== 1 && i !==0 && <Button onClick={() => handleRemoveAct(i)} layout="link" size="icon" aria-label="Remove"><TrashIcon className="w-5 h-5" aria-hidden="true" /></Button>}
                      {actions.length - 1 === i && <Button onClick={() => handleAddAct(i)} layout="link" size="icon" aria-label="Add"><PlusIcon className="w-5 h-5" aria-hidden="true" /></Button>}
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="mt-6">
              { schedule.task_type !== 'report' && schedule.task_type !== 'testcase' &&
                
                expressions.map((x, i) => {
                  return (
                    <div className="flex gap-2 my-2" key={i}>
                      <div className="mt-3" style={{ display: i===0 ? 'block' : 'none', }}>
                        <h1 className="text-gray-600 dark:text-gray-300 font-bold mr-2" style={{ fontSize: '1.5em',}}>if </h1>
                      </div>
                      <Select className="rounded-md p-2 mt-1" 
                        style={{ display: i===0 ? 'none' : 'block', width: '5rem', }}
                        name="joiner"
                        value={x.joiner}
                        onChange={e => handleExpChange(e, i)}
                      >
                        <option value="and">and</option>
                        <option value="or">or</option>
                      </Select>
                      <Select className="rounded-md p-2 mt-1" 
                        style={{ width: '12rem',}}
                        name="data_type"
                        value={x.data_type}
                        onChange={e => handleExpChange(e, i)}
                      >
                        <option value="">–––</option>
                        { schedule.task_type === 'scan' ? null : <option value="test_score">test score</option>}
                        { schedule.task_type === 'scan' ? null : <option value="seo_delta" >seo delta</option>}
                        { schedule.task_type === 'scan' ? null : <option value="pwa_delta" >pwa delta</option>}
                        { schedule.task_type === 'scan' ? null : <option value="crux_delta" >crux delta</option>}
                        { schedule.task_type === 'scan' ? null : <option value="performance_delta">performance delta</option>}
                        { schedule.task_type === 'scan' ? null : <option value="accessibility_delta">accessibility delta</option>}
                        { schedule.task_type === 'scan' ? null : <option value="best_practices_delta">best practices delta</option>}
                        { schedule.task_type === 'scan' ? null : <option value="pageWeight_delta" >page weight delta</option>}
                        { schedule.task_type === 'scan' ? null : <option value="images_delta">images delta</option>}
                        { schedule.task_type === 'scan' ? null : <option value="domComplexity_delta">DOM complex delta</option>}
                        { schedule.task_type === 'scan' ? null : <option value="javascriptComplexity_delta">JS complex delta</option>}
                        { schedule.task_type === 'scan' ? null : <option value="badJavascript_delta" >bad JS delta</option>}
                        { schedule.task_type === 'scan' ? null : <option value="jQuery_delta">jQuery delta</option>}
                        { schedule.task_type === 'scan' ? null : <option value="cssComplexity_delta">CSS complex delta</option>}
                        { schedule.task_type === 'scan' ? null : <option value="badCSS_delta">bad CSS delta</option>}
                        { schedule.task_type === 'scan' ? null : <option value="fonts_delta" >fonts delta</option>}
                        { schedule.task_type === 'scan' ? null : <option value="serverConfig_delta">server config delta</option>}
                        { schedule.task_type === 'scan' ? null : <option value="avg_image_score">avg image score</option>}
                        { schedule.task_type === 'scan' ? null : <option value="image_scores">image scores</option>}
                        { schedule.task_type === 'scan' ? <option value="health">health</option> : <option value="current_health">health</option>}
                        { schedule.task_type === 'scan' ? <option value="logs"># error logs</option> : null}
                        { schedule.task_type === 'scan' ? <option value="lighthouse_average">Lighthouse average</option> : <option value="current_lighthouse_average">Lighthouse average</option>}
                        { schedule.task_type === 'scan' ? <option value="seo" >seo score</option> : null}
                        { schedule.task_type === 'scan' ? <option value="pwa" >pwa score</option> : null}
                        { schedule.task_type === 'scan' ? <option value="crux" >crux score</option> : null}
                        { schedule.task_type === 'scan' ? <option value="performance">performance score</option> : null}
                        { schedule.task_type === 'scan' ? <option value="best_practices">best practices score</option> : null}
                        { schedule.task_type === 'scan' ? <option value="accessibility">accessibility score</option> : null}
                        { schedule.task_type === 'scan' ? <option value="yellowlab_average">Yellow Lab average</option> : <option value="current_yellowlab_average">Yellow Lab average</option>}
                        { schedule.task_type === 'scan' ? <option value="pageWeight" >page weight score</option> : null}
                        { schedule.task_type === 'scan' ? <option value="images">images score</option> : null}
                        { schedule.task_type === 'scan' ? <option value="domComplexity">DOM complex score</option> : null}
                        { schedule.task_type === 'scan' ? <option value="javascriptComplexity">JS complex score</option> : null}
                        { schedule.task_type === 'scan' ? <option value="badJavascript" >bad JS score</option> : null}
                        { schedule.task_type === 'scan' ? <option value="jQuery">jQuery score</option> : null}
                        { schedule.task_type === 'scan' ? <option value="cssComplexity">CSS complex score</option> : null}
                        { schedule.task_type === 'scan' ? <option value="badCSS">bad CSS score</option> : null}
                        { schedule.task_type === 'scan' ? <option value="fonts" >fonts score</option> : null}
                        { schedule.task_type === 'scan' ? <option value="serverConfig">server config score</option> : null}
            
                      </Select>
                      <Select className="rounded-md p-2 mt-1" 
                        style={{ width: '4rem',}}
                        name="operator"
                        value={x.operator}
                        onChange={e => handleExpChange(e, i)}
                      >
                        <option value="">––</option>
                        <option value="<=">≤</option>
                        <option value=">=">≥</option>
                      </Select>
                      <Input
                        className="rounded-md p-2 mt-1"
                        style={{ width: '5rem',}}
                        name="value"
                        value={x.value}
                        onChange={e => handleExpChange(e, i)}
                      />
                      <div className="mt-3" style={{ width: '5rem',}}>
                        {expressions.length !== 1 && i !==0 && <Button onClick={() => handleRemoveExp(i)} layout="link" size="icon" aria-label="Remove"><TrashIcon className="w-5 h-5" aria-hidden="true" /></Button>}
                        {expressions.length - 1 === i && <Button onClick={() => handleAddExp(i)} layout="link" size="icon" aria-label="Add"><PlusIcon className="w-5 h-5" aria-hidden="true" /></Button>}
                      </div>
                    </div>
                  );
                })
            
              
              }

              { schedule.task_type === 'testcase' &&

                expressions.map((x, i) => {
                  return (
                    <div className="flex gap-2" key={i}>
                      <div className="mt-3" style={{ display: i===0 ? 'block' : 'none', }}>
                        <h1 className="text-gray-600 dark:text-gray-300" style={{ fontSize: '1.5em',}}>if </h1>
                      </div>
                      <Select className="rounded-md p-2 mt-1" 
                        style={{ width: '10rem', }}
                        name="data_type"
                        value={x.data_type}
                        onChange={e => handleExpChange(e, i)}
                      >
                        <option value="testcase">testcase</option>
                      </Select>
                      <Select className="rounded-md p-2 mt-1"
                        style={{ width: '10rem', }}
                        name="value"
                        value={x.value}
                        onChange={e => handleExpChange(e, i)}
                      >
                        <option value="True">passed</option>
                        <option value="False">failed</option>
                      </Select>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
        </ModalBody>
        <ModalFooter>
          <div className="hidden sm:block">
            <Button layout="outline" onClick={handleAddAutomation} disabled={isLoading}>
            <Spinner display={isLoading}/>
              {props.schedule.automation ? 'Update' : 'Submit'}
            </Button>
          </div>
          <div className="block w-full sm:hidden">
            <Button block size="large" layout="outline" onClick={handleAddAutomation} disabled={isLoading}>
            <Spinner display={isLoading}/>
              {props.schedule.automation ? 'Update' : 'Submit'}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default AutomationModal


