import routes from '../../routes/sidebar'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as Icons from '../../icons'
import SidebarSubmenu from './SidebarSubmenu'
import SiteModal from '../Modals/SiteModal'
import { Badge, } from '@windmill/react-ui'
import store from '../../store'


function Icon({ icon, ...props }) {
  const Icon = Icons[icon]
  return <Icon {...props} />
}



function SidebarContent() {

  // connecting metrics to redux store
  const metrics = useSelector(state => state.metrics.metrics);


  return (
    <div className="py-4 text-gray-500 dark:text-gray-400 h-full relative">
      <a className="ml-6 text-lg font-bold text-gray-800 dark:text-gray-200" href="/">
        Scanerr
      </a>
      <ul className="mt-6">
        {routes.map((route) =>
          route.routes ? (
            <SidebarSubmenu route={route} key={route.name} />
          ) : (
            <li className="relative py-3" key={route.name}>
              <NavLink
                to={route.path}
                className={({ isActive }) =>
                  [
                    isActive ? 
                      "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 text-gray-800 dark:text-gray-100 border-l-[.25rem] rounded-tr-lg rounded-br-lg border-blue-600 pl-6" 
                        : 
                      "inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 border-l-[.25rem] rounded-tr-lg rounded-br-lg border-transparent pl-6",
                  ].join(" ")
                }
              >
                <Icon className="w-5 h-5" aria-hidden="true" icon={route.icon} />
                <span className="ml-4">{route.name}</span>
                {route.badge && <div className='ml-4'><Badge type={route.badge}>{route.badgeText}</Badge></div>}
                {route.name === 'Issues' && <div className='ml-4'><Badge type='warning'>{(metrics !== undefined && metrics !== null && metrics.open_issues > 0) && metrics.open_issues}</Badge></div>}
              </NavLink>
            </li>
          )
        )}
      </ul>
      <div className="px-6 my-6" >
        <SiteModal/>
      </div>
      <div className="bottom-2 absolute border-t-2 border-gray-100 dark:border-gray-900 w-full">
        <div className='px-6 my-3'>
          <h4 className='text-[.9rem] font-semibold truncate w-[90%]'>{store.getState().team.team.name}</h4>
          <h5 className='text-[.7rem] truncate w-[90%]'>{store.getState().auth.account.email}</h5>
        </div>
      </div>
    </div>
  )
}

export default SidebarContent
