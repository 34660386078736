import React, { useState, useEffect } from 'react';
import { 
  Input, Button, Select, Label
} from '@windmill/react-ui';
import axios from "axios";
import toast from 'react-hot-toast';
import { TrashIcon, PlusIcon } from '../../icons';
import { DocumentTextIcon, EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom'
import $ from 'jquery'
import Loader from '../Buttons/Loader';
import Tooltip from '../Tooltip/Tooltip';
import CopyCaseModal from '../Modals/CopyCaseModal';
import TestcaseModal2 from '../Modals/TestcaseModal2';




function CaseCard(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/ops/case'

  const emptyStep = {
    action: {
      type: '', 
      element: '',
      path: '',
      value: '',
      key: ''
    }, 
    assertion: {
      type: '',
      element: '',
      value: '',
    }, 
  }

  const case_id = props.case ? props.case.id : ''
  const [name, setName] = useState(props.case ? props.case.name : '');
  const [steps, setSteps] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [displayImages, setDisplayImages] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    if (props.case){
      setName(props.case.name);
      fetch(props.case.steps.url)
        .then(r => r.json())
        .then((out) => {
          setSteps(out)
          setLoading(false)
        }).catch((e)=>{
          console.log(e)
          setLoading(false)
        })
    }
    // eslint-disable-next-line
  },[props?.case, props, url])


  // handle input change
  const handleStepChange = (e, index) => {
    const { name, value } = e.target;
    const obj = e.target.getAttribute('data-obj')
    const list = [...steps];
    list[index][obj][name] = value;
    setSteps(list);
  };
  const handleRemoveStep = index => {
    const list = [...steps];
    list.splice(index, 1);
    setSteps(list);
  };

  const handleAssertRemove = (index) => {
    var blankAssertion = {
      type: '',
      element: '',
      value: '',
    }
    const list = [...steps];
    list[index]['assertion'] = blankAssertion;
    setSteps(list);
  };

  // handle click event of the Add button
  const handleAddStep = (index=null) => {
    if (index===null){
      var newSteps = [...steps, emptyStep]
    }else {
      var newSteps = [
        ...steps.slice(0, index),
        emptyStep,
        ...steps.slice(index)
      ];
    }
    setSteps(newSteps);
    
  };


  const displayItem = (id, display=null) => {
    if (display !== null){
      if (display === false){
        $("#"+id).hide()
      }else if(display === true){
        $("#"+id).show()
      }
    }else {
      if($("#"+id).is(":visible")){
        $("#"+id).hide()
      }else{
          $("#"+id).show()
      }
    }
  }


  const toggleImages = (display) => {
    for (var i in steps){
      if (steps[i]['action']['img'] !== null){
        displayItem(`img-${i}`, display)
      }
    }
  }


  const handleAddCase = (evt) => {
    evt.preventDefault();
    const data = {
      "case_id": case_id,
      "name": name,
      "steps": steps,
    }

    if (name === ''){
      toast.error('Case needs a name!')
      return
    }

    setIsLoading(true);
    

    axios.post(`${url}`, data)
    .then((res) => {
      if (res.data) {
        setIsLoading(false);
        toast.success(props.case ? 'Case updated!' : 'Case created!');
        if (!props.case){
          navigate(`/case/${res.data.id}`)
        }
      }
    })
    .catch((e) => {
      if (e.response) {
        toast.error(e.response.data.reason)
        setIsLoading(false);
      }
    })
  }



  if (loading) {
    return <Loader item={'Case Steps'}/>
  }



  return (

    <div className='min-w-0 rounded-lg shadow-xs bg-white dark:bg-gray-800 relative pb-4 px-4 mb-32'>
      <div className='sticky top-0 bg-white dark:bg-gray-800 pt-3'>
        <div className="flex justify-between mt-3 mb-3">
          <div className='flex justify-start'>
            <Label className="mb-3 mr-8">
                <span>Case Name:</span>
              <Input 
                className='rounded-md p-2'
                placeholder="Nickname you'll remember" 
                value={name} 
                style={{ width: '15rem',}}
                onChange={e => {setName(e.target.value)}}
              />
            </Label>
            <div>
              <div className='flex justify-start'>
                <span className={`mt-6 text-xs font-semibold py-1 px-2 rounded-xl ${props.case.type === 'generated' ? 'text-gray-700 bg-gray-100 dark:text-gray-100 dark:bg-gray-700' : 'bg-blue-100 dark:bg-blue-600 text-blue-600 dark:text-blue-100'} mx-2`}>
                  {String(props.case.type).toUpperCase()}
                </span>
              </div>
            </div>
          </div>
            
          <div className='flex justify-end'>
            <div className="mt-5 mx-2">
              <Tooltip content='save case'>
                <Button onClick={handleAddCase} layout="outline" size="small" disabled={isLoading} aria-label="Save Case" className='px-1'>
                  <DocumentTextIcon className="w-5 h-5" aria-hidden="true"/>
                </Button>
              </Tooltip>
            </div>
            <div className="mt-5 mx-2">
              <Tooltip content='toggle images'>
                <Button 
                  onClick={() => {
                    toggleImages(!displayImages)
                    setDisplayImages(!displayImages)
                  }} 
                  layout="outline" 
                  size="small" 
                  aria-label="toggle images" 
                  className='px-1'
                >
                  {!displayImages ? <EyeIcon className="w-5 h-5" aria-hidden="true"/> : <EyeOffIcon className="w-5 h-5" aria-hidden="true"/>}
                </Button>
              </Tooltip>
            </div>
            <div className="mt-5 mx-2">
              <Tooltip content='copy case'>
                <CopyCaseModal case={props.case} type='icon-btn'/>
              </Tooltip>
            </div>
            <div className="mt-5 mx-2">
              <Tooltip content='run testcase'>
                <TestcaseModal2 case={props.case} type='icon-btn'/>
              </Tooltip>
            </div>
          </div>
        </div>
        <hr/>
      </div>
      
    
    <div className="mt-3 mb-8 overflow-auto"> 
      {steps.map((x, i) => {
          return (

            <div className="py-4" key={i}>
              <div className='flex justify-start'>
                <h1 className="text-gray-600 dark:text-gray-300 my-2 mr-4" style={{ fontSize: '1.1em',}}>Step {i+1}</h1>
                <div className="my-2" style={{ width: '5rem',}}>
                  {steps.length !== 1 &&  <Button onClick={() => handleRemoveStep(i)} layout="link" size="icon" aria-label="Remove"><TrashIcon className="w-5 h-5" aria-hidden="true" /></Button>}
                  <Button onClick={() => handleAddStep(i)} layout="link" size="icon" aria-label="Add"><PlusIcon className="w-5 h-5" aria-hidden="true" /></Button>
                </div>
              </div>
              <div className='flex gap-2'>
                <Select className="mt-1 rounded-md p-2" 
                  style={{ width: '8rem', maxHeight: '3em'}}
                  name="type"
                  value={x.action.type}
                  data-obj='action'
                  onChange={(e) => {handleStepChange(e, i)}}
                >
                  <option value="">–––</option>
                  <option value="navigate">navigate</option>
                  <option value="click">click</option>
                  <option value="change">change</option>
                  <option value="keyDown">key down</option>
                  <option value="scroll">scroll</option>
                </Select>
                
                <Input
                  className="mt-1 rounded-md p-2"
                  style={{ width: '30rem', display: (x.action.type === 'click' || x.action.type === 'change') ? 'block' : 'none',}}
                  placeholder='#content > main > footer > div.flex.flex-col.items-center '
                  name="element"
                  value={x.action.element}
                  data-obj='action'
                  onChange={e => handleStepChange(e, i)}
                />

                <Input
                  className="mt-1 rounded-md p-2"
                  style={{ width: '25rem',  display: x.action.type === 'navigate' ? 'block' : 'none',}}
                  placeholder='/blog'
                  name="path"
                  value={x.action.path}
                  data-obj='action'
                  onChange={e => handleStepChange(e, i)}
                />

                <Input
                  className="mt-1 rounded-md p-2"
                  style={{ width: '25rem', display: (x.action.type === 'change' || x.action.type === 'scroll') ? 'block' : 'none',}} 
                  placeholder='some testing text or x,y scroll coordinates'
                  name="value"
                  value={x.action.value}
                  data-obj='action'
                  onChange={e => handleStepChange(e, i)}
                />

                <Input
                  className="mt-1 rounded-md p-2"
                  style={{ width: '8rem', display: x.action.type === 'keyDown' ? 'block' : 'none',}} 
                  placeholder='Enter'
                  name="key"
                  value={x.action.key}
                  data-obj='action'
                  onChange={e => handleStepChange(e, i)}
                />


              </div>

              

              <div className='flex gap-2' id={`assertion-${i}`} style={{ display: x.assertion.type === '' ? 'none' : 'flex', }}>
                <Select className="mt-1 rounded-md p-2" 
                  style={{ width: '8rem', maxHeight: '3em'}}
                  name="type"
                  value={x.assertion.type}
                  data-obj='assertion'
                  onChange={e => handleStepChange(e, i)}
                >
                  <option value="">–––</option>
                  <option value="match">match</option>
                  <option value="exists">exists</option>
                </Select>
                
                <Input
                  className="mt-1 rounded-md p-2"
                  style={{ width: '30rem', }}
                  placeholder='#content > main > footer > div.flex.flex-col.items-center'
                  name="element"
                  value={x.assertion.element}
                  data-obj='assertion'
                  onChange={e => handleStepChange(e, i)}
                />

                <Input
                  className="mt-1 rounded-md p-2"
                  style={{ width: '25rem', display: x.assertion.type === 'match' ? 'block' : 'none', }} 
                  placeholder='this is some testing text...'
                  name="value"
                  value={x.assertion.value}
                  data-obj='assertion'
                  onChange={e => handleStepChange(e, i)}
                />

                <div className='my-auto'>
                  <Button 
                    id={`assert-delete-btn-${i}`}
                    onClick={() => {
                      displayItem(`assertion-${i}`)
                      displayItem(`assert-btn-${i}`)
                      handleAssertRemove(i)
                      }}
                    layout="link" 
                    size="icon" 
                    aria-label="Remove"
                  >
                    <TrashIcon className="w-5 h-5" aria-hidden="true" />
                  </Button>
                </div>

              </div>
              
              {/* toggle display of element image */}
               
                <div className='flex gap-6 mt-3'>
                  <div 
                    className={`${x.action.type === '' ? 'hidden' : (x.assertion.type === '' ? 'block' : 'hidden')}`} 
                    id={`assert-btn-${i}`} layout='primary' 
                  >
                    <button 
                      className={`flex text-[12px] px-2 py-1 dark:text-blue-100 text-gray-700 ring-gray-700 dark:ring-gray-400 rounded-lg ring-1 bg-blue-100 dark:bg-blue-600 ml-1`}
                      onClick={() => {
                        displayItem(`assertion-${i}`)
                        displayItem(`assert-btn-${i}`)
                        }}
                      >
                      Assertion
                      <PlusIcon className='h-3 w-3 my-auto ml-1'/>
                    </button>
                  </div>

                  {x.action.img &&
                    <div>
                      <button className='text-[12px] px-2 py-1 dark:text-gray-400 text-gray-700 ring-gray-700 dark:ring-gray-400 rounded-lg ring-1 mr-2 ml-1' onClick={() => displayItem(`img-${i}`)}>
                        Toggle Image
                      </button>
                    </div>
                  }
                  {x.action.img &&
                    <div>
                      <img id={`img-${i}`} className='max-h-32 max-w-lg' src={`data:image/png;base64,${x.action.img}`} style={{display: 'none'}}/>
                    </div>
                  }

                </div>
              

            </div>
            
          );
        })}
        <div onClick={() => handleAddStep(null)} className='max-w-lg cursor-pointer flex justify-center mt-20 p-1 px-4 rounded dark:bg-gray-700 bg-gray-50 hover:shadow hover:-translate-y-[2px] duration-200 ease-in-out border-dashed border-4 dark:border-gray-600 border-gray-400'>
          <p className='dark:text-gray-400 text-sm text-gray-500 font-semibold'>Add New Step</p>
        </div>
      </div>
    </div>

  )
}

export default CaseCard


