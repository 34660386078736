import React, {useEffect, useState} from 'react';
import { Globe, Copy, Activity, ClockIcon } from '../../icons'
import InfoCard from './InfoCard'
import RoundIcon from '../RoundIcon'
import Loader from '../Buttons/Loader';



function HomeStatsCard(props) {

    const [data, setData] = useState(props.data)


    useEffect(() => {
        setData(props?.data)
        // eslint-disable-next-line
    },[props?.data])

    if(data === null){
        return <Loader item={'Stats'}/>
    }


    return (
        <>

            <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4 grid-cols-2">

                <InfoCard title="Sites" value={data.sites} progress={data.sites_usage}>
                    <RoundIcon
                    icon={Globe}
                    iconColorClass="text-green-500 dark:text-green-100"
                    bgColorClass="bg-green-100 dark:bg-green-500"
                    className="mr-4"
                    />
                </InfoCard>

                <InfoCard title="Scans" value={data.scans} progress={data.scans_usage}>
                    <RoundIcon
                    icon={Activity}
                    iconColorClass="text-blue-500 dark:text-blue-100"
                    bgColorClass="bg-blue-100 dark:bg-blue-500"
                    className="mr-4"
                    />
                </InfoCard>

                <InfoCard title="Tests" value={data.tests} progress={data.tests_usage}>
                    <RoundIcon
                    icon={Copy}
                    iconColorClass="text-teal-500 dark:text-teal-100"
                    bgColorClass="bg-teal-100 dark:bg-teal-500"
                    className="mr-4"
                    />
                </InfoCard>

                <InfoCard title="Schedules" value={data.schedules} progress={data.schedules_usage}>
                    <RoundIcon
                    icon={ClockIcon}
                    iconColorClass="text-orange-500 dark:text-orange-100"
                    bgColorClass="bg-orange-100 dark:bg-orange-500"
                    className="mr-4"
                    />
                </InfoCard>
            </div>
        
        </>
    )
}



export default HomeStatsCard