import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router';
import PageTitle from '../components/Typography/PageTitle'
import BackButton from '../components/Buttons/BackButton';
import StepsCard from '../components/Cards/StepsCard';
import StepCard from '../components/Cards/StepCard';
import DeleteTestcaseModal from '../components/Modals/DeleteTestcaseModal'
import Gear from '../components/Buttons/Gear';
import { useParams } from 'react-router-dom'
import { Badge } from '@windmill/react-ui'
import axios from 'axios'
import Loader from '../components/Buttons/Loader';




function TestcaseDetail() {
    
    const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
    const testcaseUrl = `${BACKEND_URL}/v1/ops/testcase`;
    const getSiteUrl = `${BACKEND_URL}/v1/ops/site/`;
    const [testcase, setTestcase] = useState(null);
    const [site, setSite] = useState(null);
    const [step, setStep] = useState(null);
    const [stepIndex, setStepIndex] = useState(0)
    const { id } = useParams();
    const navigate = useNavigate();


    const loadData = async () => {
        // get testcase info
        if (id){
            await axios.get(`${testcaseUrl}/${id}`)
            .then((r) => {
                setTestcase(r.data)
                axios.get(`${getSiteUrl}${r.data.site}`)
                    .then((r) => {
                        setSite(r.data)
                    }).catch((err) => {
                        console.log(err)
                    });
            })
            .catch((err) => {
                console.log(err)
    
            });
        }
      }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line 
      },[])


    const getStep = (newstep, i) => {
        setStep(newstep)
        setStepIndex(i)
    }


    const getTestcaseStatus = () => {
        if (testcase.time_completed === null){
            return (<span className='text-gray-700 dark:text-white bg-gray-200 dark:bg-gray-700 inline-flex px-2 text-xs font-medium leading-5 rounded-full'>Working</span>)
        } else {
            if (testcase.passed){
                return (<Badge type='success'>Passed</Badge>)    
            }
            return (<Badge type='danger'>Failed</Badge>)
        }
    }
    

    useEffect(() => {
        const interval = setInterval(() => {
            loadData();
        }, 10000);
        return () => {
          clearInterval(interval);
        };
         // eslint-disable-next-line
      }, []);


    const urlLength = () => {
        var text_size = 'text-2xl'
        var len = site ? site.site_url.length : ''
        if (len >= 50 ){
            var text_size = 'text-xl'
        }else if (len > 36 && len < 50){
            var text_size = 'text-xl'
        }else if (len < 50){
            var text_size = 'text-2xl'
        }
        return text_size
    }


    if (!testcase || !site){
        return <Loader item={'Testcase'}/>
    }



    return (
        <>
        <div className='md:flex justify-between'>
            <PageTitle>
                <div className="flex mb-2">
                    <div className=''>
                        <div className='flex justify-start'>
                            <div>
                                <span className='text-center px-2 py-1 rounded-full text-[11px] bg-gray-200 dark:bg-gray-600 font-semibold dark:text-white text-gray-700'>
                                    TESTCASE
                                </span>
                            </div>
                            <h1 className='font-semibold text-gray-400 dark:text-gray-500 text-[.9rem] ml-3 mt-1'>
                                {new Date(testcase.time_created).toLocaleDateString()}
                            </h1>
                        </div>
                        <h3 onClick={() => navigate(`/site/${site.id}`)} className={`hover:underline cursor-pointer max-w-lg ${urlLength()}`}>{site ? site.site_url : ''}</h3>
                        <div className='md:hidden block'>
                            <DeleteTestcaseModal type='icon' testcase={testcase} callBack={() => {navigate(`/site/${testcase.site}`)}}/>
                        </div>
                    </div>
                </div>
            </PageTitle>
            <div>
                <div className='py-2 px-4 border-2 border-gray-300 dark:border-gray-400 max-w-[32rem] mt-10 mb-8 rounded-lg'>
                    <div className='flex justify-between'>
                        <div className='flex justify-start'>
                            <h3 className='mr-4 text-xl font-semibold text-gray-600 dark:text-gray-300'>Status:</h3> 
                            <div>
                                { !testcase.time_completed ?
                                    <span className='text-gray-700 dark:text-white bg-gray-200 dark:bg-gray-700 inline-flex px-2 text-xs font-medium leading-5 rounded-full'>working <Gear size={'ml-2 h-4 w-4 mt-[.1rem]'} color={'dark:text-gray-200 text-gray-600'}/></span>
                                :
                                    <Badge type={testcase.passed ? 'success' : 'danger'}>{testcase.passed ? 'Passed' : 'Failed'}</Badge>
                                }
                            
                            </div>
                        </div>

                        <div className='ml-10 flex justify-start'>
                            <h3 className='mr-4 text-xl font-semibold text-gray-600 dark:text-gray-300'>Total Steps:</h3> 
                            <h3 className='text-lg font-bold text-gray-600 dark:text-gray-100'>{testcase.steps.length}</h3>
                        </div>
                    </div>
                    <div className='flex justify-between mt-4'>
                        <div className='flex justify-start'>
                            <h3 className='mr-4 text-xl font-semibold text-gray-600 dark:text-gray-300'>Case:</h3>
                            <div onClick={() => navigate(`/case/${testcase.case}`)} className='cursor-pointer text-sm font-semibold px-2 py-1 dark:bg-gray-800 bg-gray-200 dark:text-white rounded-md text-center w-auto'>
                                <pre><code>{testcase.case_name}</code></pre>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='mt-8 md:block hidden ml-1'>
                <DeleteTestcaseModal type='button' testcase={testcase} callBack={() => {navigate(`/site/${testcase.site}`)}}/>
            </div>
        </div>

        <BackButton site_id={testcase.site}/>
        <div className='grid md:grid-cols-2 gap-6 mb-8'>
            <div className=''>
                {/* listed steps */}
                <StepsCard testcase={testcase} callBack={getStep}/>
            </div>
            <div>
                {/* step details card */}
                <StepCard step={step ? step : testcase.steps[0]} stepIndex={stepIndex}/>
            </div>

        </div>
        
        </>
    )
}

export default TestcaseDetail