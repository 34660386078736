import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { LeftArrow } from '../../icons'


function BackButton(props) {

    const navigate = useNavigate()
    const location = useLocation()

    const nav = () => {
        if (props.link){
            navigate(props.link)
        }else if(location.key !== 'default'){
            navigate(-1)
        } else (
            navigate('/')
        )
    }

    return(
        <>
            <div 
                onClick={nav}
                className="flex cursor-pointer text-sm -mt-2 mb-6  hover:underline dark:hover:text-blue-300 hover:text-blue-600 dark:text-gray-100 text-gray-600 max-w-[8rem]">
                <LeftArrow /> <div><p className="ml-2"> {props.text ? props.text : 'go back'}</p></div>
            </div>
        </>
    )
}


export default BackButton