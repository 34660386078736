import React, {useState, useEffect} from 'react'
import { Input, Button } from '@windmill/react-ui'
import { LeftArrow, ExternalLink } from '../icons'
import { CheckIcon, ArrowRightIcon } from '@heroicons/react/outline';
import { PlayIcon } from '@heroicons/react/solid'
import WelcomeImg from '../assets/img/welcome.jpg'
import axios from "axios";
import Spinner from '../components/Buttons/Spinner'
import Gear from '../components/Buttons/Gear';
import store from '../store';
import toast from 'react-hot-toast';





function Onboard() {

    const [ready, setReady] = useState(false)
    const [loading, setLoading] = useState(false)
    const [siteUrl, setSiteUrl] = useState('')
    const [site, setSite] = useState(null)
    const [progress, setProgress] = useState(10)
    const [step, setStep] = useState(0)
    const [showVideo, setShowVideo] = useState(false)
    const member = store.getState().member.member
    const token = store.getState().auth.token
    const headers = {'Authorization': `Bearer ${token}`}
    const configs = {headers: headers, skipAuthRefresh: true}

    const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
    const site_url = `${BACKEND_URL}/v1/ops/site`
    const page_url = `${BACKEND_URL}/v1/ops/page`
    const process_url = `${BACKEND_URL}/v1/ops/process`


    const checkMember = async () => {
        if (!token){
            console.log(window.location.href)
            localStorage.setItem("next", window.location.href);
            window.location.href = '/register'
        }
        else if (member.type !== 'admin'){
            window.location.href = '/'
        }else {
            await axios.get(site_url, configs)
                .then(async (r) => {
                    if (r.data.count > 1){
                        window.location.href = '/'
                    }else if (r.data.count === 1 ){
                        
                        setSite(r.data.results[0])
                        await axios.get(`${page_url}?site_id=${r.data.results[0].id}`, configs)
                            .then((r) => {
                                var pages = r.data.results
                                var pages_count = r.data.count
                                var num_working = 0
                                var prog = 0
                                for (var i in pages){
                                    console.log('checking progress for member')
                                    if (!pages[i].info.latest_scan.time_completed){
                                        num_working += 1
                                    }
                                }

                                axios.get(`${process_url}?type=case`, configs)
                                    .then((r) => {
                                        // saving process
                                        var proc_count = r.data.count
                                        var proc = proc_count > 0 ? r.data.results[0] : {'progress': 100}

                                        // calculating progress from scans
                                        if (num_working === 0 && pages_count !== 0 && proc.progress >= 100){
                                            window.location.href = '/'
                                            setReady(false)
                                        } else if (pages_count !== 0) {
                                            prog = (25 + ((50/pages_count) * (pages_count - num_working)))
                                            console.log(`progress from scans -> ${prog}%`)
                                            setStep(2)
                                            setReady(true)
                                        } else if (pages_count === 0) {
                                            console.log(`resetting progress back to -> 10%`)
                                            prog = 10 
                                            setStep(2)
                                            setReady(true)
                                        }

                                        // calculating progress from Process (case generating)
                                        prog += (25 * (proc.progress / 100))
                                        console.log(`progress -> ${prog}%`)
                                        setProgress(prog)
                                        
                                    })

                               
                                
                                
                            })
                        
                    }else {
                        setReady(true)
                    }
                }).catch((e) => {
                    console.log(e)
                    // window.location.href = '/'
                    // setReady(false)
                })
        }
        
    }

    const checkProgress = () => {
        if (site) {
            axios.get(`${site_url}/${site.id}`, configs)
                .then((r) => {
                    // logic to set progress
                    if (r.data.time_crawl_completed && progress < 25){
                        setProgress(25)
                    }
                    console.log(`checking progress -> ${progress}`)
                    if (progress >= 25) {
                        // check for page scans
                        console.log('getting pages')
                        axios.get(`${page_url}?site_id=${site.id}`, configs)
                            .then(async (r) => {
                                var precent_complete = 0
                                var pages = r.data.results
                                var count = (50 / r.data.count)
                                for (var i in pages){
                                    if (pages[i].info.latest_scan.time_completed){
                                        precent_complete += count
                                    }
                                }
                                
                                await axios.get(`${process_url}?type=case`, configs)
                                    .then((r) => {
                                        var proc = r.data.results[0]
                                        precent_complete += (25 * (proc.progress / 100))
                                        setProgress(25 + precent_complete)
                                        console.log(`progress -> ${25 + precent_complete}%`)
                                    })

                            })
                            .catch((e) => {
                                console.log(e)
                            })
                    }
                })
        }
    }

    useEffect(() => {
        checkMember()
    }, [])


    useEffect(() => {
        if (progress === 100){
            // display next step
            setTimeout(function () {
                console.log('site fully onboarded')
                setStep(3)
            }, 5000);
        }
    }, [progress])


    useEffect(() => {
        const interval = setInterval(() => {
            console.log('triggered interval')
            checkProgress();
        }, 5000);
        return () => {
          clearInterval(interval);
        };
        // eslint-disable-next-line
      }, [site, progress]);



    const handleKeyDownSite = (evt) => {
        if (evt.key === 'Enter') {
            addSite()
        }
      }


    const addSite = async () => {
        
        setLoading(true);
        const data = {
            "site_url": siteUrl,
            "onboarding": true,
        }

        if(siteUrl === ''){
            setLoading(false);
            toast.error('please enter a site url');
        } else if (!siteUrl.startsWith('https://') && !siteUrl.startsWith('http://')){
            setLoading(false);
            toast.error('please use http:// or https://');
        }else{
            await axios.post(`${site_url}`, data, configs)
                .then((r) => {
                    console.log(r.data)
                    setSite(r.data)
                    setProgress(10)
                    setLoading(false);
                    setSiteUrl('');
                    toast.success('Site added!');
                    setStep(step+1)
                    checkProgress();
                }).catch((e) => {
                    if (e.response) {
                    toast.error(e.response.data.reason)
                    setLoading(false);
                    }
                })
        }


    }


    const checkMark = () => {
        return (
            <div className={`rounded-full bg-green-300 text-green-700 p-1 h-5 w-5`}>
                <CheckIcon className='h-3 w-3'/>
            </div>
        )
    }


    const viewSite = () => {
        window.location.href = `/site/${site.id}`
    }



    const playVideo = () => {
        var video = document.getElementById("welcome-video"); 
        if (video.paused) {
            video.play(); 
            setShowVideo(true)
        }else {
            video.pause();
            setShowVideo(false)
        } 

    }
    


    return(
        <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
            { ready && 
                <div className={`flex-1 h-full ${step === 0 ? 'max-w-3xl' : 'max-w-xl'} mx-auto bg-white rounded-lg shadow-xl dark:bg-gray-800`}>

                    {/* video section */}
                    {(step === 0) &&
                        <div className='max-w-3xl mx-auto p-6'>
                            
                            <video controls={true} className={`rounded-lg ${!showVideo && 'hidden'}`} id='welcome-video' name="welcome video" preLoad={true} onEnded={() => setShowVideo(false)} onPause={() => setShowVideo(false)}>
                                <source src="https://storage-scanerr.sfo3.cdn.digitaloceanspaces.com/static/videos/Scanerr%20Welcome%202.mp4" type="video/mp4"/>
                            </video>

                            <div onClick={playVideo} className={`cursor-pointer ${showVideo && 'hidden'}`}>
                                <img
                                    className='rounded-lg w-full'
                                    src={WelcomeImg}
                                    alt="Welcome"
                                />
                                <div className='relative flex justify-center -top-[14rem]'>
                                    <PlayIcon className='absolute inline-flex animate-ping text-blue-600 bg-white h-20 w-20 rounded-full'/>
                                    <PlayIcon className='relative inline-flex text-blue-600 bg-white h-20 w-20 rounded-full'/>
                                </div>
                            </div>

                            <div className={`${showVideo ? 'mt-12' : '-mt-6'} mx-auto text-left`}>
                                <h3 className='dark:text-gray-300 text-gray-800 font-semibold text-2xl mb-6 text-center'>Next Steps:</h3>
                                <div className='flex justify-center'>
                                    <div>
                                    <ol className='dark:text-gray-300 text-gray-800 font-normal text-sm mb-8 '>
                                        <li className='my-2'>
                                            <div className='flex justify-start my-1'>
                                                <span>
                                                    1. Join our 
                                                </span>
                                                <a className='mx-1 underline decoration-blue-600' href='https://join.slack.com/t/scanerr/shared_invite/zt-2ecly7yps-6dFqI2hfJ062ESGjGrwpxw' target="_blank">
                                                    Slack Support Group
                                                </a>
                                                <ExternalLink className='h-4 mt-1'/>
                                            </div>
                                        </li>
                                        <li className='my-2'>2. Add your <span className='underline decoration-blue-600 cursor-pointer' onClick={() => setStep(step+1)}>First Site</span></li>
                                    </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {/* add-site section*/}
                    {(step === 1) &&
                        <div className="p-6 sm:p-12">
                            <h1 id='title' className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                                Add Your First Site
                            </h1>
                            <div>
                                <Input 
                                    className="rounded-md p-2 mt-1" 
                                    onKeyDown={handleKeyDownSite}
                                    type="text"
                                    name='site-url'
                                    placeholder="https://example.com" 
                                    value={siteUrl}
                                    onChange={e => setSiteUrl(e.target.value)}/>
                                <Button 
                                    type="submit" 
                                    block 
                                    className={`mt-4 ${(siteUrl.length > 0 && !loading)? 'cursor-pointer' : 'cursor-not-allowed'}`} 
                                    onClick={addSite} 
                                    disabled={siteUrl.length <= 0 || loading}
                                >
                                    <Spinner display={loading}/>
                                    Add Site
                                </Button>
                            </div>
                            <div 
                                onClick={() => setStep(step-1)}
                                className="flex cursor-pointer text-sm mt-6  no-underline hover:underline hover:text-blue-600 text-blue-500 max-w-sm">
                                <LeftArrow /> <div><p className="ml-2">go back</p></div>
                            </div>
                        </div>
                    }
                    {/* explainaton section */}
                    {(step === 2) &&
                        <div className="p-6 sm:p-12">
                            <div className='flex justify-start w-full'>
                                <span className='text-2xl'>🚀</span>
                                <h1 id='title' className="ml-4 mb-4 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                                    Off To The Races!
                                </h1>
                            </div>
                           
                            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                <div className="bg-blue-600 h-2.5 rounded-full transition-all ease-out duration-1000" style={{width: `${progress}%`}}></div>
                            </div>

                            <div className='mt-12 mx-auto text-left'>
                                <h3 className='dark:text-gray-300 text-gray-800 font-semibold text-xl mb-2 text-center'>What's happening now:</h3>
                                <div className='flex justify-center'>
                                    <div>
                                        <ol className='font-normal text-sm mb-6'>
                                            <li>
                                                <div className='flex justify-start my-1'>
                                                    {progress === 10 && <Gear margins={'-ml-[.10rem] -mr-[.15rem]'}/>}
                                                    {progress > 10 && checkMark()} 
                                                    <span className='dark:text-gray-300 text-gray-800 ml-3'>
                                                        Crawling your Site for Pages
                                                    </span>
                                                </div>
                                            </li>
                                            <li >
                                                <div className='flex justify-start my-1'>
                                                    {progress === 25 && <Gear margins={'-ml-[.10rem] -mr-[.15rem]'}/>}
                                                    {progress > 25 && checkMark()} 
                                                    <span className={progress >= 25 ? `dark:text-gray-300 text-gray-800 ml-3` : `dark:text-gray-500 text-gray-400 ml-3`}>
                                                        Saving the Pages in Scanerr
                                                    </span>
                                                </div>
                                            </li>
                                            <li >
                                                <div className='flex justify-start my-1'>
                                                    {(progress > 25 && progress < 75) && <Gear margins={'-ml-[.10rem] -mr-[.15rem]'}/>}
                                                    {progress >= 75 && checkMark()} 
                                                    <span className={progress > 25 ? `dark:text-gray-300 text-gray-800 ml-3` : `dark:text-gray-500 text-gray-400 ml-3`}>
                                                        Creating Scans for each Page
                                                    </span>
                                                </div>
                                            </li>
                                            <li >
                                                <div className='flex justify-start my-1'>
                                                    {(progress >= 75 && progress < 100) && <Gear margins={'-ml-[.10rem] -mr-[.15rem]'}/>}
                                                    {progress === 100 && checkMark()} 
                                                    <span className={progress > 75 ? `dark:text-gray-300 text-gray-800 ml-3` : `dark:text-gray-500 text-gray-400 ml-3`}>
                                                        Generating Cases for your Site
                                                    </span>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </div>

                                <div className='text-right w-full'>
                                    <a className='no-underline hover:underline hover:text-blue-600 text-blue-500 mt-4 text-xs' href={`/site/${site.id}`}>Skip</a>
                                </div>

                            </div>
                        </div>
                    }
                    {/* Onboard complete section */}
                    {(step === 3) &&
                        <div className="p-6 sm:p-12">

                            <div className='flex justify-start w-full'>
                                <span className=''>
                                    <div className={`rounded-full bg-green-300 text-green-700 p-1 h-7 w-7`}>
                                        <CheckIcon className='h-5 w-5'/>
                                    </div>
                                </span>
                                <h1 id='title' className="ml-4 mb-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                                   All Done Here.
                                </h1>
                            </div>

                            <p className='text-sm text-gray-600 dark:text-gray-300'>
                                Your Site has been successfully onboarded to the Scanerr Platform. 
                                You can now
                                <a
                                    className='mx-1 underline decoration-blue-600'
                                    href='https://docs.scanerr.io/guides/app.html#test' 
                                    target='_blank'
                                >
                                    Test your site
                                </a>
                                for breaking changes, view detailed Performance Audits, 
                                and Track your site's health over time. If you need anything, we're here to help. 
                                Happy Hacking! <span>💻</span>
                            </p>
                             
                            <div 
                                className="flex justify-end">
                                <Button
                                    size='small'
                                    onClick={viewSite}
                                >
                                    View Site
                                    <ArrowRightIcon className='ml-1 h-5'/>
                                </Button>
                            </div>
                        </div>
                    }

                </div>
            }
        </div>
    )
}

export default Onboard