import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Gear from '../Buttons/Gear';
import Loader from '../Buttons/Loader';
import {Table, TableHeader, TableCell, TableBody, TableRow, TableFooter,
  TableContainer, Badge, Pagination,} from '@windmill/react-ui'
import axios from 'axios'

const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;

function ScansTable(props) {

  const emptyScans = {results:[], count:0}
  const [scans, setScans] = useState(emptyScans)
  const [offSet, setOffSet] = useState(0)
  const [loading, setLoading] = useState(true)

  let getScansUrl = `${BACKEND_URL}/v1/ops/scan?lean=true&page_id=${props.page.id}&limit=10&offset=${offSet}`;

  const getData = async () => {
    await axios.get(getScansUrl)
      .then((r) => {
        setScans(r.data)
        setLoading(false)
      })
      .catch((e) => {
        setScans(emptyScans)
        setLoading(false)
      })
  }

  // !!!!! VERY IMPORTANT - this subscribes the component to listen for prop changes from the parent !!!!!!
  useEffect(() => {
    getData();
    return () => {
      getData;
    }
    // eslint-disable-next-line 
  }, [])


  useEffect(() => {
    const interval = setInterval(() => {
      getData();
    }, 20000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [offSet]);


  // setup pages control for table
  const pageTable = 1

  // pagination setup
  const resultsPerPage = 10

  // pagination change control
  function onPageChangeTable(p) {
    if(p){
      let off_set = (p-1)*10
      setOffSet(off_set)
      let paginatedUrl = `${BACKEND_URL}/v1/ops/scan?lean=true&page_id=${props.page.id}&limit=10&offset=${off_set}`
      axios.get(paginatedUrl)
        .then((r) => {
          setScans(r.data);
      })
    }
    
  }

  function getScore(scan) {
    let badge = 'neutral';
    let health = 'no data';
    let score_int = 0;
    let value = health;
    let d = 0
    
    if (scan.lighthouse !== null){
      if (scan.lighthouse.scores.average !== null){
        score_int += parseInt(scan.lighthouse.scores.average)
        d += 1
      }
    }
    if (scan.yellowlab !== null){
      if (scan.yellowlab.scores.globalScore !== null){
        score_int += parseInt(scan.yellowlab.scores.globalScore)
        d += 1
      }
    }
    if (score_int !== 0 ){
      score_int = Number(((score_int / d) * 10 / 10).toFixed(1));
      if (score_int >= 75) {
        health = 'Good';
        badge = 'success';
      } else if (score_int >= 60 && score_int < 75) {
        health = 'Okay';
        badge = 'warning';
      } else {
        health = 'Poor';
        badge = 'danger';
      }
      value = health+' - '+score_int+'%'
    }

    if (scan.time_completed === null){
      value = 'working'
      badge = 'neutral'
    }
    
    return <Badge type={badge}>{value} {value === 'working' && <Gear size={'ml-2 h-4 w-4 mt-[.1rem]'} color={'dark:text-gray-200 text-gray-600'}/>}</Badge> 
  }



  function getDate(scan) {
    if(scan.time_created){
        let date = new Date(scan.time_created).toLocaleDateString();
        let time = new Date(scan.time_created).toLocaleTimeString('en-US');
        let formatedDateTime = `${date} ${time}`
        return formatedDateTime
    }else{
        return '––';
    }
    
  }

  function getNumLogs(scan) {
    if(scan.logs){
      return scan.logs.length;
    }else{
      let data = '–'
      return data
    }
  }

  if (loading){
    return <Loader item={'Scans'}/>
  }


  return (
    <>
      <TableContainer className="mb-8 dark:bg-gray-800 bg-white">
        <h1 className="text-gray-600 dark:text-gray-300" style={{ margin: '.7em', fontSize: '1.3em', fontWeight: 600, }}>Scans</h1>
        <Table>
          <TableHeader >
            <tr className='dark:bg-gray-800 bg-white'>
              <TableCell>Created</TableCell>
              <TableCell>Health</TableCell>
              <TableCell>Errors</TableCell>
            </tr>
          </TableHeader>
          <TableBody>
            {scans.results.slice((pageTable - 1) * resultsPerPage, pageTable * resultsPerPage).map((scan, i) => (
                <TableRow key={i} className="hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer">
              
                    <TableCell>
                      <Link to={`/scan/${scan.id}`}>
                        <span className="text-sm">{getDate(scan)}</span>
                      </Link>
                    </TableCell>
                  
                  
                  <TableCell>
                    <Link to={`/scan/${scan.id}`}>
                      {getScore(scan)} 
                    </Link>
                  </TableCell>
                  
                    <TableCell>
                      <Link to={`/scan/${scan.id}`}>
                        <div>
                            <p className="font-semibold">{getNumLogs(scan)}</p>
                        </div>
                      </Link>
                    </TableCell>
                  
                </TableRow>
            ))}
          </TableBody>
        </Table>
        <TableFooter>
          <Pagination
            totalResults={scans.count}
            resultsPerPage={resultsPerPage}
            onChange={onPageChangeTable}
            label="Table navigation"
          />
        </TableFooter>
      </TableContainer>
    </>
  )
}




export default ScansTable
